import React, { useState, useEffect, useCallback, useRef } from 'react';
import feedService from '../../services/feedService';
import {
  Button,
  Spacer,
  Spinner,
  Tab,
  Tabs,
  Checkbox,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  Avatar,
} from '@nextui-org/react';
import StoryList from '@/components/StoryList';
import userService from '@/services/userService';
import Department from '@/enums/department';
import Location from '@/enums/location';
import { DatePicker } from "@nextui-org/react";
import { FeedPostLabelEnum } from '@/enums/feed';
import { parseDate } from "@internationalized/date";
import { I18nProvider, useDateFormatter } from '@react-aria/i18n';
import FeedPostDetail from '@/components/FeedPost';

function Feed() {
  const dateFormatter = useDateFormatter({
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const MediaTypes = {
    image: { name: 'Bild', emoji: '🖼️' },
    video: { name: 'Video', emoji: '🎥' },
    voice: { name: 'Sprachaufnahme', emoji: '🎤' },
    gif: { name: 'GIF', emoji: '🎞️' },
    file: { name: 'Datei', emoji: '📄' },
  };

  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [areUsersFetched, setAreUsersFetched] = useState(false);

  const pageRef = useRef(1);
  const prevTabRef = useRef('feed');
  const [activeTab, setActiveTab] = useState('feed');
  const sentinelRef = useRef(null);

  const fetchPosts = useCallback(async (more) => {
    const currentPage = more ? pageRef.current + 1 : 1;

    if (more) setIsLoadingMore(true);
    else setIsLoading(true);

    try {
      let postsData;
      if (filters) {
        const response = await feedService.getFilteredPosts(filters, currentPage, 5);
        postsData = response.postsData;
      } else {
        const response = await feedService.getPosts(currentPage, 5);
        postsData = response.postsData;
      }

      setPosts((prevPosts) =>
        currentPage === 1 ? postsData : [...prevPosts, ...postsData]
      );

      pageRef.current = currentPage;
      setHasMore(postsData.length === 5);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      if (more) setIsLoadingMore(false);
      else setIsLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!hasMore || isLoading || isLoadingMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          fetchPosts(true);
        }
      },
      { root: null, rootMargin: "0px", threshold: 1.0 }
    );

    if (sentinelRef.current) observer.observe(sentinelRef.current);

    return () => {
      if (sentinelRef.current) observer.unobserve(sentinelRef.current);
    };
  }, [hasMore, isLoading, isLoadingMore, fetchPosts]);

  const fetchUsers = useCallback(async () => {
    setIsUserLoading(true);
    try {
      const usersData = await feedService.getFeedUsers();
      setUsers(usersData.users);
      setAreUsersFetched(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsUserLoading(false);
    }
  }, []);

  useEffect(() => {
    const filter = getFilter(activeTab);
    setFilters(filter);
  }, [activeTab]);

  useEffect(() => {
    fetchPosts(false);
  }, [filters]);

  const getFilter = (tab) => {
    if (tab === 'feed') {
      return null;
    } else if (tab === 'dep') {
      return { departments: [userService.user.department] };
    } else if (tab === 'loc') {
      return { locations: [userService.user.location] };
    }
    return null;
  };

  const handleFilterSubmit = () => {
    const formattedFilters = {
      ...filters,
      maxDate: filters.maxDate ? filters.maxDate.toDate().toISOString().split('T')[0] : null,
      minDate: filters.minDate ? filters.minDate.toDate().toISOString().split('T')[0] : null,
    };

    setFilters(formattedFilters);
    setIsFilterModalOpen(false);
    fetchPosts(false);
  };

  const handleModalOpen = () => {
    setIsFilterModalOpen(true);
    if (!areUsersFetched) {
      fetchUsers();
    }
  };

  const handleCancelModal = () => {
    setIsFilterModalOpen(false);
    setActiveTab(prevTabRef.current);
  };

  return (
    <div className='w-full flex justify-center'>
      <div className="flex-1 max-w-screen-xl content-center overflow-scroll p-4">
        <div className="flex items-center gap-x-3">
          <h1 className="text-3xl font-bold leading-9 text-default-foreground">Stories</h1>
        </div>
        <h2 className="mt-2 text-small text-default-500 mb-6">
          Sei schnell - Stories sind nur 48h zu sehen. Wer weiß... vielleicht gibt es ja etwas zu gewinnen.
        </h2>
        <StoryList />
        <div className="flex items-center gap-x-3 mt-6">
          <h1 className="text-3xl font-bold leading-9 text-default-foreground">Feed</h1>
        </div>
        <h2 className="mt-2 text-small text-default-500 mb-6">
          Es gibt News?! Hier bist Du richtig!
        </h2>
        <Tabs
          fullWidth
          radius='full'
          classNames={{
            cursor: "bg-content1 dark:bg-content1",
            panel: "w-full p-0 pt-4",
          }}
          onSelectionChange={(tabKey) => {
            prevTabRef.current = activeTab;
            setActiveTab(tabKey);
            if (tabKey === 'custom') {
              handleModalOpen();
            }
          }}
          selectedKey={activeTab}
        >
          <Tab key="feed" title="Mein Feed">
            <div />
          </Tab>
          <Tab key="dep" title={Department[userService.user.department].departmentName}>
            <div />
          </Tab>
          <Tab key="loc" title={Location[userService.user.location].locationName}>
            <div />
          </Tab>
          <Tab key="custom" title="Filtern">
            <div />
          </Tab>
        </Tabs>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-96">
            <Spinner />
          </div>
        ) : (
          <>
            {posts.map((post) => (
              <>
                <FeedPostDetail post={post} />
                <Spacer y={4} />
              </>
            ))}
            {isLoadingMore && <div className="flex justify-center my-4"><Spinner /></div>}
            <div ref={sentinelRef} className="h-1"></div>
            {!hasMore && <div className="text-center my-4">Keine weiteren Posts verfügbar.</div>}
          </>
        )}
      </div>
      <Modal isOpen={isFilterModalOpen} backdrop='blur' onOpenChange={(isOpen) => {
        if (!isOpen) {
          handleCancelModal();
        }
      }}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Filter</ModalHeader>
              <ModalBody>
                {isUserLoading ? (
                  <div className="flex justify-center mb-4">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="flex gap-2">
                      <I18nProvider locale="de-DE">
                        <DatePicker
                          variant="bordered"
                          label="Erstellt ab"
                          value={filters?.minDate ?? null}
                          onChange={(date) => setFilters({ ...filters, minDate: date })}
                        />
                      </I18nProvider>
                      <I18nProvider locale="de-DE">
                        <DatePicker
                          variant="bordered"
                          label="Erstellt bis"
                          value={filters?.maxDate ?? null}
                          onChange={(date) => setFilters({ ...filters, maxDate: date })}
                        />
                      </I18nProvider>
                    </div>
                    <div className="flex flex-col gap-2">
                      <Select
                        label="Standorte"
                        variant="bordered"
                        placeholder="Wähle Standorte"
                        selectedKeys={new Set(filters?.locations ?? [])}
                        onSelectionChange={(selected) => setFilters({ ...filters, locations: Array.from(selected) })}
                        selectionMode='multiple'
                      >
                        {Object.keys(Location).map((key) => (
                          <SelectItem key={key}>
                            {Location[key].emoji} {Location[key].locationName}
                          </SelectItem>
                        ))}
                      </Select>
                      <Select
                        label="Abteilungen"
                        variant="bordered"
                        placeholder="Wähle Abteilungen"
                        selectedKeys={new Set(filters?.departments ?? [])}
                        onSelectionChange={(selected) => setFilters({ ...filters, departments: Array.from(selected) })}
                        selectionMode='multiple'
                      >
                        {Object.keys(Department).map((key) => (
                          <SelectItem key={key}>
                            {Department[key].emoji} {Department[key].departmentName}
                          </SelectItem>
                        ))}
                      </Select>
                      <Select
                        label="Gruppen"
                        variant="bordered"
                        placeholder="Wähle Gruppen"
                        selectedKeys={new Set(filters?.groups ?? [])}
                        onSelectionChange={(selected) => setFilters({ ...filters, groups: Array.from(selected) })}
                        selectionMode='multiple'
                      >
                        {Object.values(FeedPostLabelEnum).map((group) => (
                          <SelectItem key={group.label} textValue={group.name}>
                            {group.emoji} {group.name}
                          </SelectItem>
                        ))}
                      </Select>
                      <Select
                        label="Autor"
                        variant="bordered"
                        placeholder="Wähle Autor"
                        selectedKeys={new Set(filters?.authors ?? [])}
                        onSelectionChange={(selected) => setFilters({ ...filters, authors: Array.from(selected) })}
                        selectionMode='multiple'
                      >
                        {users.map((user) => (
                          <SelectItem key={user.id} textValue={user.firstname}>
                            <div className="flex gap-2 items-center">
                              <Avatar alt={user.firstname} className="flex-shrink-0" size="sm" src={user.avatarUrl} />
                              <div className="flex flex-col">
                                <span className="text-small">{user.firstname} {user.surname}</span>
                                <span className="text-tiny text-default-400">{user.mail}</span>
                              </div>
                            </div>
                          </SelectItem>
                        ))}
                      </Select>
                      <Select
                        label="Medien / Anhänge"
                        variant="bordered"
                        placeholder="Wähle Medien"
                        selectedKeys={new Set(filters?.mediaTypes ?? [])}
                        onSelectionChange={(selected) => setFilters({ ...filters, mediaTypes: Array.from(selected) })}
                        selectionMode='multiple'
                      >
                        {Object.keys(MediaTypes).map((key) => (
                          <SelectItem key={key} textValue={MediaTypes[key].name}>
                            {MediaTypes[key].emoji} {MediaTypes[key].name}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                    <Checkbox
                      checked={filters?.commentedByMe ?? false}
                      onChange={(e) => setFilters({ ...filters, commentedByMe: e.target.checked })}
                    >
                      von mir kommentiert
                    </Checkbox>
                    <Select
                      label="Sortierung"
                      variant="bordered"
                      selectedKeys={new Set([filters?.sortDirection ?? []])}
                      onSelectionChange={(selected) => setFilters({ ...filters, sortDirection: Array.from(selected)[0] })}
                      selectionMode='single'
                    >
                      <SelectItem key="asc">Aufsteigend</SelectItem>
                      <SelectItem key="desc">Absteigend</SelectItem>
                    </Select>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button flat auto onClick={handleCancelModal}>Abbrechen</Button>
                <Button color='primary' onClick={handleFilterSubmit}>Filter anwenden</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Feed;