import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { Quill } from "react-quill";

const CustomUndo = () => (
  <Icon icon="solar:undo-left-round-outline" width={24} height={24} />
);
const CustomRedo = () => (
  <Icon icon="solar:undo-right-round-outline" width={24} height={24} />
);

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

export const QuillToolbar = () => (
  <div id="toolbar" className="toolbar-modern">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3" title="Header">
        <option value="1">H1</option>
        <option value="2">H2</option>
        <option value="3">Text</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" title="Bold">
        <Icon icon="ph:bold" width={20} height={20} />
      </button>
      <button className="ql-italic" title="Italic">
        <Icon icon="ph:italic" width={20} height={20} />
      </button>
      <button className="ql-underline" title="Underline">
        <Icon icon="ph:underline" width={20} height={20} />
      </button>
      <button className="ql-strike" title="Strike">
        <Icon icon="ph:strikethrough" width={20} height={20} />
      </button>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"/>
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-video" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
