import GalleryComponent from "@/components/Galllery";
import { Image, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useState } from "react";

function WaWiSellOrderArticleComponent({ article }) {
    const [lightboxIndex, setLightboxIndex] = useState(-1);

    const openLightbox = (index) => {
        setLightboxIndex(index);
    };

    const closeLightbox = () => {
        setLightboxIndex(-1);
    };

    const galleryImages = article.images.map(image => ({ src: image, width: 4, height: 3 }));

    return (
        <>
            <Table
                isStriped
                isCompact
                aria-label="Article Details Table"
                shadow="none"
                fullWidth
                frame={false}
                classNames={{ wrapper: "p-0 mt-4" }}
            >
                <TableHeader>
                    <TableColumn>Eigenschaft</TableColumn>
                    <TableColumn>Ursprung</TableColumn>
                    <TableColumn>nach Prüfung</TableColumn>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell className="text-small text-default-500">Ankaufspreis</TableCell>
                        <TableCell className="text-small text-default-500">
                            {article.sumOrigin ? `${article.sumOrigin} €` : "-"}
                        </TableCell>
                        <TableCell
                            className={`text-small text-default-500 ${article.sumNew && article.sumNew !== article.sumOrigin ? "text-primary font-bold" : ""
                                }`}
                        >
                            {article.sumNew ? `${article.sumNew} €` : "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className="text-small text-default-500">Zustand</TableCell>
                        <TableCell className="text-small text-default-500">
                            {article.conditionOrigin || "-"}
                        </TableCell>
                        <TableCell
                            className={`text-small text-default-500 ${article.conditionNew && article.conditionNew !== article.conditionOrigin ? "text-primary font-bold" : ""
                                }`}
                        >
                            {article.conditionNew || "-"}
                        </TableCell>
                    </TableRow>
                    {article.questions && article.questions.length > 0 ? (
                        article.questions.map((question, index) => (
                            <TableRow key={index}>
                                <TableCell className="text-small text-default-500">
                                    {question.short || "-"}
                                </TableCell>
                                <TableCell className="text-small text-default-500">
                                    {typeof question.customer === "boolean" ? (question.customer ? "Ja" : "Nein") : "-"}
                                </TableCell>
                                <TableCell
                                    className={`text-small text-default-500 ${question.customer && !question.result ? "text-primary font-bold" : ""
                                        }`}
                                >
                                    {typeof question.result === "boolean"
                                        ? question.result
                                            ? "Ja"
                                            : question.customer
                                                ? "Nein"
                                                : "Nein"
                                        : "-"}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3} className="text-center text-default-500">
                                Keine Fragen verfügbar
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className="overflow-x-auto w-full mt-4">
                {article.images?.length > 0 && (
                    <div className="flex flex-nowrap space-x-3">
                        {article.images.map((image, index) => (
                            <div
                                className="w-[200px] h-[200px] overflow-hidden flex-shrink-0 rounded-xl"
                                key={index}
                                onClick={() => openLightbox(index)}
                            >
                                <Image
                                    isZoomed
                                    className="object-cover w-[200px] h-[200px]"
                                    alt="Beitragsbild"
                                    radius="sm"
                                    src={image}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {article.description?.trim() && (
                <div
                    className="text-default-500 mt-4"
                    dangerouslySetInnerHTML={{
                        __html: article.description.replace(/\r?\n/g, '<br>'),
                    }}
                />
            )}
            {lightboxIndex >= 0 && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <GalleryComponent
                        images={galleryImages}
                        initialIndex={lightboxIndex}
                        onClose={closeLightbox}
                    />
                </div>
            )}
        </>
    );
}

export default WaWiSellOrderArticleComponent;