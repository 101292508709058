import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image, Spinner } from "@nextui-org/react";
import userService from '../services/userService';
import './Order';
import foodService from '../services/foodService';
import OrderComponent from './Order';
import OrderItemComponent from './OrderItem';
import Countdown from 'react-countdown';
import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Icon } from '@iconify/react/dist/iconify.js';
const formatter = buildFormatter(germanStrings)

const delivered = {
    iconClass: "fa-solid fa-moped mr-2",
    text: "Als geliefert markieren",
    action: 'delivered'
};
const storno = {
    iconClass: "fa-solid fa-trash mr-2",
    text: "Stornieren",
    action: 'cancel'
};

const FoodComponent = ({ offer, onClick }) => {
    const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [menuOptions, setMenuOptions] = useState([]);
    const [numOrderItems, setNumOrderItems] = useState(1);
    const [orderItems, setOrderItems] = useState([<OrderItemComponent offerId={offer.id} numberKey={numOrderItems} />]);
    const [displayOrders, setDisplayOrders] = useState(false);
    const [ordersLoaded, setOrdersLoaded] = useState(false);

    const toggleOrders = () => {
        if (ordersLoaded) {
            setDisplayOrders(!displayOrders);
        } else {
            fetchOrders();
            setDisplayOrders(true);
        }
    }
    const fetchOrders = async () => {
        try {
            const fetchedOrders = await foodService.getOrdersById(offer.id);
            setOrders(fetchedOrders);
            setOrdersLoaded(true);
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    }
    function addOrder(event) {
        setOrderSubmitLoading(true);
        event.preventDefault();
        event.stopPropagation();
        try {
            const addedOrder = foodService.addOrder(offer.id, numOrderItems * 2, event.target);
            if (addedOrder) {
                offer.orderCount++;
                fetchOrders();
                setNumOrderItems(1);
                document.getElementsByClassName("newOrderForm")[0].reset();
                setOrderSubmitLoading(false);
            }
        } catch (error) {
            console.error('Could not add order ' + event.target + ' to offer ' + offer.id + error);
        }
    }

    const onAddItem = () => {
        setNumOrderItems(numOrderItems + 1);
        setOrderItems([...orderItems, <OrderItemComponent offerId={offer.id} numberKey={numOrderItems} />]);
    }

    const countdownRender = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return "";
        } else if (hours === 0 && minutes === 0) {
            return <span className="white-red-badge text-small mb-auto ml-auto mr-3">{seconds} Sekunden</span>
        } else if (hours === 0) {
            return <span className="white-red-badge text-small mb-auto ml-auto mr-3">{minutes}:{seconds}</span>
        } else {
            return <span className="white-red-badge text-small zxred mb-auto ml-auto mr-3">{hours}h {minutes}min</span>
        }
    }
    useEffect(() => {
        const updateMenuOptions = () => {
            if (userService.user.id === offer.authorId) {
                if (!offer.deliveryTime && !offer.cancelled) {
                    setMenuOptions([delivered]);
                    setMenuOptions((prevMenuOptions) => ([...prevMenuOptions, storno]));
                }
            }
        }
        updateMenuOptions();
    }, [userService.user.id, offer.authorId, offer.cancelled, offer.deliveryTime]);

    return (
        <>
            <Card className="w-full rounded-lg border bg-background shadow p-2">
                <CardHeader className="justify-between">
                    <div className="flex gap-4">
                        <Avatar isBordered radius="full" size="md" src={offer.user.avatarUrl} />
                        <div className="flex flex-col gap-1 items-start justify-center">
                            <h5 className='text-small tracking-tight text-default-400'>{offer.user.firstname} {offer.user.surname} bestellt bei</h5>
                            <h4 className="text-small font-semibold leading-none text-default-600">{offer.name}</h4>
                        </div>
                    </div>
                    <Dropdown backdrop="blur">
                        <DropdownTrigger>
                            <Button
                                color="primary"
                                radius="full"
                                size="md"
                                variant="solid">Menü ansehen
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Menu Dropdown">
                            {offer.menuUrl && (
                                <DropdownItem
                                    key="menu-web"
                                    onPress={() => window.open(offer.menuUrl, '_blank')}
                                    startContent={
                                        <Icon
                                            className="text-default-500"
                                            height={24}
                                            icon="solar:globus-linear"
                                            width={24}
                                        />
                                    }
                                >
                                    Webseite aufrufen
                                </DropdownItem>
                            )}
                            {offer.menuPdf && (
                                <DropdownItem
                                    key="menu-pdf"
                                    onPress={() => window.open(offer.menuPdf, '_blank')}
                                    startContent={
                                        <Icon
                                            className="text-default-500"
                                            height={24}
                                            icon="solar:document-text-outline"
                                            width={24}
                                        />
                                    }
                                >
                                    Speisekarte (PDF)
                                </DropdownItem>
                            )}
                            {offer.imageUrl && (
                                <DropdownItem
                                    key="menu-img"
                                    onPress={() => window.open(offer.imageUrl, '_blank')}
                                    startContent={
                                        <Icon
                                            className="text-default-500"
                                            height={24}
                                            icon="solar:gallery-minimalistic-linear"
                                            width={24}
                                        />
                                    }
                                >
                                    Speisekarte (Bild)
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </CardHeader>
                <CardBody>
                    <div>
                        {offer.paymentPreferences.cashAccepted &&
                            <span className="rounded-full p-2 mx-1 bg-gray-200 "><i className="fa-regular fa-money-bill-wave mr-2"></i>Bar</span>
                        }
                        {offer.paymentPreferences.payPal &&
                            <a href="https://www.paypal.com"><span className="rounded-full p-2 mx-1 bg-gray-200 ">
                                <i className="fa-brands fa-paypal mr-2"></i>PayPal</span>
                            </a>
                        }
                        <div className='mt-4 px-2 text-orange-700'>{offer.cancellationReason}</div>
                        <div className='mt-5 px-2'>{offer.comment}</div>
                    </div>
                    {offer.deliveryTime && offer.deliveryTime.milliseconds && (
                        <div className='px-2'>
                            <i className="fa-regular fa-plate-utensils pr-2"></i>
                            Um {new Date(offer.deliveryTime.milliseconds).toLocaleTimeString('de-DE')} geliefert. Guten Appetit!
                        </div>
                    )}
                </CardBody>
                <CardFooter className="gap-3 justify-between flex-col">
                    <div className="flex-row w-full justify-between flex">
                        <span className="p-2 bg-white col-6">
                            <button onClick={toggleOrders} className="p-0 font-semibold text-default-400 text-small">{offer.orderCount} Bestellungen</button>
                        </span>
                        <span className="p-2 text-default-400 text-small col-6">
                            <TimeAgo date={new Date(offer.created.milliseconds)} formatter={formatter} />
                        </span>
                    </div>
                    {displayOrders &&
                        orders.map(order => (
                            <div className="mx-1 w-full">
                                <OrderComponent key={order.id} offerId={offer.id} order={order} authorId={offer.authorId} />
                            </div>
                        ))}
                    {offer.orderEnd.milliseconds > Date.now() && !offer.cancelled &&
                        <Card className="w-full">
                            <CardHeader>
                                <b>Mitbestellen?</b>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <form className="newOrderForm" onSubmit={addOrder}>
                                    {orderItems}
                                    <div className="flex mt-3 justify-between w-full">
                                        {<Button style={{ backgroundColor: "white" }} className="p-1 rounded-full zxred" onClick={onAddItem}>
                                            Mehr bestellen
                                            <i className="fa-solid fa-plus"></i>
                                        </Button>}
                                        <Button type="submit" isLoading={orderSubmitLoading} className="rounded-full bg-zxred">
                                            Bestellung abschicken
                                            <i className="fa-solid fa-paper-plane"></i>
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    }
                </CardFooter>
            </Card>
        </>
    );
};

export default FoodComponent;
