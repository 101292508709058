"use client";

import React from "react";
import { RadioGroup, Radio, Input, Textarea } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import { pushRoutingOptions } from "./types";
import { useAppPushCreator } from "./AppPushCreator";

const AppPushCreatorType = React.forwardRef(({ className, ...props }, ref) => {
  const { state, updateField } = useAppPushCreator();

  const radioClassNames = {
    base: cn(
      "inline-flex m-0 bg-default-100 items-center justify-between",
      "flex-row-reverse w-full max-w-full cursor-pointer rounded-xl p-4 border-medium border-transparent",
      "data-[selected=true]:border-primary",
    ),
    control: "bg-primary text-secondary-foreground",
    wrapper: "group-data-[selected=true]:border-primary",
    label: "text-small text-default-500 font-medium",
    labelWrapper: "m-0",
  };

  const handleRouteChange = (value) => {
    updateField("referenceError", false);
    updateField("argsError", false);
    updateField("route", value);
  };

  return (
    <>
      <div className="text-3xl font-bold leading-9 text-default-foreground">
        App-Ziel
      </div>
      <div className="py-4 text-default-500">
        Wo soll der Nutzer beim Anklicken der Push-Nachricht landen?
      </div>
      <form
        ref={ref}
        className={cn("flex grid grid-cols-12 flex-col gap-4 py-8", className)}
        {...props}
      >
        <RadioGroup
          className="col-span-12"
          classNames={{
            wrapper: "gap-4",
          }}
          name="route"
          value={state.route}
          onValueChange={handleRouteChange}
        >
          {pushRoutingOptions.map((option) => (
            <Radio classNames={radioClassNames}
              key={option.value}
              value={option.value}
              isDisabled={option.excludeIf.includes(state.audience)
              }
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
        <Input
          className="col-span-12"
          variant="bordered"
          type="text"
          size="lg"
          label="Zielreferenz"
          placeholder="ASIN, Artikel-ID, Auftragsnummer etc."
          value={state.reference}
          labelPlacement="inside"
          isInvalid={state.referenceError}
          isDisabled={
            !state.route ||
            pushRoutingOptions.some(option => option.value === state.route && option.requireReference === false)
          }
          onChange={(e) => {
            updateField("reference", e.target.value);
            updateField("referenceError", false);
          }}
        />
        <Textarea
          className="col-span-12"
          variant="bordered"
          type="text"
          size="lg"
          label="Arguments"
          value={state.args}
          placeholder="Arguments im JSON-Format"
          labelPlacement="inside"
          isInvalid={state.argsError}
          isDisabled={
            !state.route ||
            pushRoutingOptions.some(option => option.value === state.route && option.showArguments === false)
          }
          onChange={(e) => {
            updateField("args", e.target.value);
            updateField("argsError", false);
          }}
        />
      </form>
    </>
  );
});

AppPushCreatorType.displayName = "AppPushCreatorType";

export default AppPushCreatorType;
