import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs, BreadcrumbItem, Spinner,
    Button,
    Accordion,
    AccordionItem,
} from '@nextui-org/react';
import { useNavigate, useLocation } from 'react-router-dom';
import wikiService from '@/services/wikiService';
import WikiContent from './WikiContent';

const itemClasses = {
    base: "py-0 w-full",
    trigger: "data-[hover=true]:bg-default-100 rounded-lg h-14 flex items-center",
};

const WikiCategory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];

    const [wiki, setWiki] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWiki = async () => {
            try {
                const wiki = await wikiService.getWikiContent(id);
                setWiki(wiki.wiki);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchWiki();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleBreadcrumbClick = () => {
        navigate(`/wiki`);
    };

    const handleCreateClick = () => {
        if (id) {
            navigate(`/wiki/create?collection=${id}`);
        }
    };

    const defaultContent =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

    return (
        <div className="w-full flex-1 p-4">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Wiki</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Deine Knowledge-Base für Deinen Alltag im ZOXS-Universum.
            </h2>
            <Breadcrumbs variant="bordered" radius='md'>
                <BreadcrumbItem onClick={handleBreadcrumbClick} onPress={handleBreadcrumbClick} >Wiki Home</BreadcrumbItem>
                {wiki && wiki.title ? (
                    <BreadcrumbItem>{wiki.title}</BreadcrumbItem>
                ) : (
                    <BreadcrumbItem>Unbekannte Kategorie</BreadcrumbItem>
                )}
            </Breadcrumbs>
            {loading ? (
                <div className="flex justify-center my-6">
                    <Spinner size="lg" />
                </div>
            ) : error ? (
                <div className="mt-6">
                    <h3 className="text-xl font-semibold text-red-500">Fehler</h3>
                    <p>{error}</p>
                </div>
            ) : wiki ? (
                <>
                    <div className="flex items-center gap-x-3 mt-6">
                        <h1 className="text-3xl font-bold leading-9 text-default-foreground">{wiki.title}</h1>
                    </div>
                    <h2 className="mt-2 text-small text-default-500 mb-6">
                        {wiki.description}
                    </h2>
                    <Accordion
                        isCompact
                        selectionMode="multiple"
                        showDivider={false}
                        className="rounded-lg border bg-background p-4 shadow"
                        variant="shadow"
                        itemClasses={itemClasses}
                    >
                        {wiki.wikis.map((item) => (
                            <AccordionItem
                                key={item.id}
                                aria-label={`Accordion ${item.id}`}
                                title={item.title}
                            >
                                <WikiContent item={item} />
                            </AccordionItem>
                        ))}
                    </Accordion>
                </>
            ) : (
                <div className="mt-6">
                    <h3 className="text-xl font-semibold text-default-500">Ups</h3>
                    <p>Dieses Wiki gibt es nicht.</p>
                </div>
            )}
            <Button
                onPress={handleCreateClick}
                radius='full'
                className="fixed bottom-6 right-6 z-50"
                color="primary"
                auto
            >
                Inhalt erstellen
            </Button>
        </div>
    );
};

export default WikiCategory;