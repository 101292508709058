import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { Button, Card, Checkbox, cn, Select, SelectItem, Spacer, useDisclosure } from "@nextui-org/react";
import { FeedPostLabelEnum } from "@/enums/feed";
import userService from "@/services/userService";
import { v4 as uuidv4 } from 'uuid';
import { processFiles } from "@/helpers/fileHelper";
import { uploadFileToFirebase } from "@/helpers/storageHelper";
import Editor from "@/components/editor/Editor";
import { useNavigate } from "react-router-dom";
import feedService from "@/services/feedService";
import useToast from '@/hooks/useToast';

export default function FeedCreate() {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const [sharing, setSharing] = React.useState(true);
    const [editorContent, setEditorContent] = React.useState('');
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [label, setLabel] = React.useState(null);
    const [labelError, setLabelError] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    const onDrop = React.useCallback((acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter(file => file.size <= 50 * 1024 * 1024);
        const uniqueFiles = filteredFiles.filter((file) =>
            !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        );
        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    }, [uploadedFiles]);

    const handleRemoveFile = (fileName) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const handleEditorChange = (content) => {
        console.log(content);
        setEditorContent(content);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '*/*',
        maxSize: 50 * 1024 * 1024
    });

    const handlePost = async () => {
        setUploading(true);

        if (!label || label.isEmpty) {
            setLabelError(true);
        }

        if (labelError) {
            setUploading(false);
            return;
        }

        let text = '';
        if (editorContent && editorContent.ops && editorContent.ops.length > 0) {
            editorContent.ops.forEach(op => {
                if (op.insert) {
                    text += op.insert;
                }
            });
        }
        
        if (text.length < 1 && uploadedFiles.length === 0) {
            setUploading(false);
            alert("Bitte füge entweder Text oder Dateien hinzu.");
            return;
        }

        let processedFiles = null;
        if (uploadedFiles.length > 0) {
            processedFiles = await processFiles(uploadedFiles);
            console.log('Processed Files:', processedFiles);
        }

        const imageMedia = [];
        const videoMedia = [];
        const fileMedia = [];
        const voiceMedia = [];

        const postId = uuidv4();

        if (processedFiles) {
            for (const image of processedFiles.images) {
                const imageUrl = await uploadFileToFirebase(image, `internal/posts/${postId}/images/`);
                imageMedia.push(imageUrl);
            }

            for (const video of processedFiles.videos) {
                const videoUrl = await uploadFileToFirebase(video.url, `internal/posts/${postId}/videos/`);
                const thumbnailUrl = await uploadFileToFirebase(video.thumbnail, `internal/posts/${postId}/thumbnails/`);
                videoMedia.push({
                    url: videoUrl,
                    thumbnail: thumbnailUrl,
                    duration: video.duration,
                    aspectRatio: video.aspectRatio,
                });
            }

            for (const audio of processedFiles.audios) {
                const audioUrl = await uploadFileToFirebase(audio.url, `internal/posts/${postId}/voices/`);
                voiceMedia.push({
                    url: audioUrl,
                    duration: audio.duration,
                });
            }

            for (const file of processedFiles.others) {
                const fileUrl = await uploadFileToFirebase(file.url, `internal/posts/${postId}/files/`);
                fileMedia.push({
                    filename: file.filename,
                    url: fileUrl,
                    size: file.size,
                    filetype: file.filetype,
                });
            }
        }

        const post = {
            id: postId,
            label: label,
            groups: [label],
            sharing: sharing,
            textContent: JSON.stringify(editorContent.ops),
            imageMedia: imageMedia,
            videoMedia: videoMedia,
            fileMedia: fileMedia,
            voiceMedia: voiceMedia,
        };

        try {
            const result = await feedService.createPost(JSON.stringify(post));
            
            if (result === true) {
                showToast('Post erfolgreich erstellt.', 'success');
                handleClose();
            } else {
                showToast('Post nicht erstellt.', 'error');
            }
        } catch (error) {
            showToast('Post nicht erstellt.', 'error');
            console.error('Error creating Post content:', error.message);
        } finally {
            setUploading(false);
        }
    };

    const handlePreview = () => {
        onOpen();
    };

    const handleClose = useCallback(() => {
        navigate('/feed');
      }, [navigate]);

    return (
        <div className="w-full flex-1 p-4">
            <Icon
                className="text-default-400 absolute top-4 right-4 cursor-pointer"
                icon="solar:close-square-bold"
                width={28}
                height={28}
                onClick={handleClose}
            />

            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    Post erstellen
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Poste einen Beitrag für den Feed.
            </h2>

            <div className="mt-4 flex flex-col lg:flex-row gap-3">
                <div className="w-full lg:w-2/3">
                    <Editor value={editorContent} onChange={handleEditorChange}></Editor>
                </div>
                <div className="w-full lg:w-1/3">
                    <Card className="rounded-lg border bg-background shadow p-2">
                        <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center cursor-pointer">
                            <input {...getInputProps()} />
                            <p className="text-gray-500">
                                Dateien reinziehen oder per Klick hochladen
                            </p>
                            <p className="text-xs text-gray-400">
                                (Alle Dateitypen erlaubt, max. 50 MB pro Datei)
                            </p>
                        </div>
                        {uploadedFiles.length > 0 && (
                            <div className="mt-4">
                                <h3 className="text-small text-default-500 mb-2">Anhänge:</h3>
                                <ul>
                                    {uploadedFiles.map((file, index) => (
                                        <li key={index} className="flex justify-between items-center text-small text-default-400">
                                            {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                            <Icon
                                                className="text-default-400 cursor-pointer"
                                                icon="mdi:close"
                                                width={20}
                                                height={20}
                                                onClick={() => handleRemoveFile(file.name)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <Spacer y={2} />
                        <Select
                            variant="underlined"
                            label="Gruppe wählen"
                            selectedKey={label}
                            onChange={(e) => {
                                setLabel(e.target.value);
                                setLabelError(false)
                            }}
                            isInvalid={labelError}
                        >
                            {Object.values(FeedPostLabelEnum).map((label) => (
                                <SelectItem key={label.label}>
                                    {`${label.emoji} ${label.name}`}
                                </SelectItem>
                            ))}
                        </Select>
                        <Spacer y={2} />
                        <Checkbox
                            aria-label='sharing'
                            classNames={{
                                base: cn(
                                    "inline-flex bg-content1",
                                    "hover:bg-content2 items-center justify-start",
                                    "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                ),
                            }}
                            isSelected={sharing}
                            onValueChange={setSharing}
                        >
                            <div>
                                <p className="mt-2 text-small text-default-500">
                                    <strong>Download & Teilen erlauben</strong>
                                </p>
                                <p className="mt-2 text-small text-default-500">
                                    Erlaubt das Teilen von Posts an @zoxs.de Mailadressen sowie den Download von Bildern und Videos. Dateien können immer heruntergeladen werden.
                                </p>
                            </div>
                        </Checkbox>
                        <Spacer y={2} />
                        <div className="flex space-x-1">
                            <Button fullWidth={true} isLoading={uploading} color="primary" onPress={handlePost}>
                                Beitrag posten
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
