import { AppUser } from "../user/appUser";
import PaymentPreferences from "../user/paymentPreferences";

class FoodOffer {
    constructor({
        id,
        authorId,
        orderMasterId,
        name,
        location,
        paymentPreferences,
        orderCount = 0,
        orderSum = 0,
        orders = [],
        comment = null,
        imageUrl = null,
        menuUrl = null,
        menuPdf = null,
        cancellationReason = null,
        dateOfOffer,
        created,
        orderEnd,
        estDeliveryTime,
        deliveryTime = null,
        cancelled = null,
        user
    }) {
        this.id = id;
        this.authorId = authorId;
        this.orderMasterId = orderMasterId;
        this.name = name;
        this.location = location;
        this.paymentPreferences = paymentPreferences instanceof PaymentPreferences ? paymentPreferences : new PaymentPreferences(paymentPreferences);
        this.orderCount = orderCount;
        this.orderSum = orderSum;
        this.orders = orders;
        this.comment = comment;
        this.imageUrl = imageUrl;
        this.menuUrl = menuUrl;
        this.menuPdf = menuPdf;
        this.cancellationReason = cancellationReason;
        this.dateOfOffer = dateOfOffer ? new Date(dateOfOffer.milliseconds) : null;
        this.created = created ? new Date(created.milliseconds) : null;
        this.orderEnd = orderEnd ? new Date(orderEnd.milliseconds) : null;
        this.estDeliveryTime = estDeliveryTime ? new Date(estDeliveryTime.milliseconds) : null;
        this.deliveryTime = deliveryTime ? new Date(deliveryTime.milliseconds) : null;
        this.cancelled = cancelled;
        this.user = user instanceof AppUser ? user : new AppUser(user);
    }

    static fromJSON(json) {
        return new FoodOffer({
            id: json.id,
            authorId: json.authorId,
            orderMasterId: json.orderMasterId,
            name: json.name,
            location: json.location,
            paymentPreferences: new PaymentPreferences(json.paymentPreferences),
            orderCount: json.orderCount,
            orderSum: json.orderSum,
            orders: json.orders,
            comment: json.comment,
            imageUrl: json.imageUrl,
            menuUrl: json.menuUrl,
            menuPdf: json.menuPdf,
            cancellationReason: json.cancellationReason,
            dateOfOffer: json.dateOfOffer ? new Date(json.dateOfOffer.milliseconds) : null,
            created: json.created ? new Date(json.created.milliseconds) : null,
            orderEnd: json.orderEnd ? new Date(json.orderEnd.milliseconds) : null,
            estDeliveryTime: json.estDeliveryTime ? new Date(json.estDeliveryTime.milliseconds) : null,
            deliveryTime: json.deliveryTime ? new Date(json.deliveryTime.milliseconds) : null,
            cancelled: json.cancelled,
            user: new AppUser(json.user)
        });
    }

    toJSON() {
        return {
            id: this.id,
            authorId: this.authorId,
            orderMasterId: this.orderMasterId,
            name: this.name,
            location: this.location,
            paymentPreferences: this.paymentPreferences.toJson(),
            orderCount: this.orderCount,
            orderSum: this.orderSum,
            orders: this.orders,
            comment: this.comment,
            imageUrl: this.imageUrl,
            menuUrl: this.menuUrl,
            menuPdf: this.menuPdf,
            cancellationReason: this.cancellationReason,
            dateOfOffer: this.dateOfOffer ? { milliseconds: this.dateOfOffer.getTime() } : null,
            created: this.created ? { milliseconds: this.created.getTime() } : null,
            orderEnd: this.orderEnd ? { milliseconds: this.orderEnd.getTime() } : null,
            estDeliveryTime: this.estDeliveryTime ? { milliseconds: this.estDeliveryTime.getTime() } : null,
            deliveryTime: this.deliveryTime ? { milliseconds: this.deliveryTime.getTime() } : null,
            cancelled: this.cancelled,
            user: this.user.toJson()
        };
    }
}

export { FoodOffer };