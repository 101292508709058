class ZoxsPushModel {
    constructor({
        id,
        title,
        message,
        type,
        referenceType,
        referenceId,
        route = null,
        args = {},
        imageUrl,
        start = null,
        end = null,
        audience,
        userIds = [],
        createdAt = null,
    }) {
        this.id = id;
        this.title = title;
        this.message = message;
        this.type = type;
        this.referenceType = referenceType;
        this.referenceId = referenceId;
        this.route = route;
        this.args = args;
        this.imageUrl = imageUrl;
        this.start = start;
        this.end = end;
        this.audience = audience;
        this.userIds = userIds;
        this.createdAt = createdAt;
    }

    static fromJson(json) {
        const convertToDate = (timestamp) => {
            if (timestamp && typeof timestamp._seconds === "number") {
                return new Date(timestamp._seconds * 1000 + Math.floor(timestamp._nanoseconds / 1e6));
            }
            return null;
        };

        return new ZoxsPushModel({
            id: json.id,
            title: json.title,
            message: json.message,
            type: json.type,
            referenceType: json.referenceType,
            referenceId: json.referenceId,
            route: json.route,
            args: json.args,
            imageUrl: json.imageUrl,
            start: convertToDate(json.start),
            end: convertToDate(json.end),
            audience: json.audience,
            userIds: json.userIds,
            createdAt: convertToDate(json.createdAt),
        });
    }

    toJson() {
        const convertToFirestoreTimestamp = (date) => {
            if (date instanceof Date) {
                const seconds = Math.floor(date.getTime() / 1000);
                const nanoseconds = (date.getTime() % 1000) * 1e6;
                return { _seconds: seconds, _nanoseconds: nanoseconds };
            }
            return null;
        };

        return {
            id: this.id,
            title: this.title,
            message: this.message,
            type: this.type,
            referenceType: this.referenceType,
            referenceId: this.referenceId,
            route: this.route,
            args: this.args,
            imageUrl: this.imageUrl,
            start: convertToFirestoreTimestamp(this.start),
            end: convertToFirestoreTimestamp(this.end),
            audience: this.audience,
            userIds: this.userIds,
            createdAt: convertToFirestoreTimestamp(this.createdAt),
        };
    }
}

module.exports = ZoxsPushModel;
