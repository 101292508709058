class GifMedia {
    constructor({
        title,
        type,
        id,
        slug,
        url,
        bitlyGifUrl,
        bitlyUrl,
        embedUrl,
        username,
        source,
        rating,
        contentUrl,
        sourceTld,
        sourcePostUrl,
        isSticker,
        importDatetime,
        trendingDatetime,
        user,
        images
    }) {
        this.title = title;
        this.type = type;
        this.id = id;
        this.slug = slug;
        this.url = url;
        this.bitlyGifUrl = bitlyGifUrl;
        this.bitlyUrl = bitlyUrl;
        this.embedUrl = embedUrl;
        this.username = username;
        this.source = source;
        this.rating = rating;
        this.contentUrl = contentUrl;
        this.sourceTld = sourceTld;
        this.sourcePostUrl = sourcePostUrl;
        this.isSticker = isSticker;
        this.importDatetime = importDatetime;
        this.trendingDatetime = trendingDatetime;
        this.user = user;
        this.images = images;
    }

    static fromJson(json) {
        return new GifMedia(json);
    }

    toJson() {
        return {
            title: this.title,
            type: this.type,
            id: this.id,
            slug: this.slug,
            url: this.url,
            bitlyGifUrl: this.bitlyGifUrl,
            bitlyUrl: this.bitlyUrl,
            embedUrl: this.embedUrl,
            username: this.username,
            source: this.source,
            rating: this.rating,
            contentUrl: this.contentUrl,
            sourceTld: this.sourceTld,
            sourcePostUrl: this.sourcePostUrl,
            isSticker: this.isSticker,
            importDatetime: this.importDatetime,
            trendingDatetime: this.trendingDatetime,
            user: this.user,
            images: this.images
        };
    }
}

export { GifMedia };