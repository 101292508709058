import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NoPermission from '@/components/NoPermission';
import Feed from './Feed';
import FeedCreate from './FeedCreate';

const FeedPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const [activeComponent, setActiveComponent] = useState('Feed');

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/feed/post/create')) {
      setActiveComponent('PostEditorCreate');
    } else if (path.startsWith('/feed/post/edit') && id) {
      setActiveComponent('PostEditorEdit');
    } else if (path.startsWith('/feed/post/') && id) {
      setActiveComponent('PostDetail');
    } else {
      setActiveComponent('Feed');
    }
  }, [location.pathname, id]);

  return (
    <>
      {activeComponent === 'Feed' && <Feed />}
      {activeComponent === 'PostEditorCreate' && <FeedCreate />}
      {activeComponent === 'PostEditorEdit' && <FeedCreate id={id} />}
      {activeComponent === 'PostDetail' && <Feed id={id} />}
    </>
  );
};

export default FeedPage;