import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { Button, Card, Input, Select, SelectItem, Spacer, Spinner } from "@nextui-org/react";
import { processFiles } from "@/helpers/fileHelper";
import { uploadFileToFirebase } from "@/helpers/storageHelper";
import { useLocation, useNavigate } from "react-router-dom";
import Editor from "@/components/editor/Editor";
import wikiService from "@/services/wikiService";
import { v4 as uuidv4 } from 'uuid';
import useToast from '@/hooks/useToast';

export default function WikiEditor() {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const location = useLocation();
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [wikis, setWikis] = useState([]);
    const [selectedWiki, setSelectedWiki] = React.useState(null);
    const [selectedWikiError, setSelectedWikiError] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const query = new URLSearchParams(location.search);
    const collectionId = query.get("collection");
    const [title, setTitle] = React.useState("");

    useEffect(() => {
        const fetchWikis = async () => {
            try {
                const { wikiData } = await wikiService.getWikiCollections();
                setWikis(wikiData);
    
                if (collectionId) {
                    const matchedWiki = wikiData.find((wiki) => wiki.id === collectionId);
                    if (matchedWiki) {
                        setSelectedWiki(matchedWiki.id);
                    } else {
                        console.error(`No matching wiki found for collection ID: ${collectionId}`);
                        setError(`Collection not found for ID: ${collectionId}`);
                    }
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchWikis();
    }, [collectionId]);

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const onDrop = React.useCallback((acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter(file => file.size <= 50 * 1024 * 1024);
        const uniqueFiles = filteredFiles.filter((file) =>
            !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name)
        );
        setUploadedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    }, [uploadedFiles]);

    const handleRemoveFile = (fileName) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '*/*',
        maxSize: 50 * 1024 * 1024
    });

    const handleWikiSelectionChange = (e) => {
        console.log(e.target.value);
        setSelectedWiki(e.target.value);
      };

    const closeWikiCreate = () => {
        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/wiki');
        }
    };

    const handleWikiUpload = async () => {
        setUploading(true);

        if (!selectedWiki || selectedWiki.isEmpty) {
            setSelectedWikiError(true);
        }

        if (title.length < 5) {
            setUploading(false);
            alert("Bitte füge einen Titel hinzu.");
            return;
        }

        let text = '';
        if (editorContent && editorContent.ops && editorContent.ops.length > 0) {
            editorContent.ops.forEach(op => {
                if (op.insert) {
                    text += op.insert;
                }
            });
        }
        
        if (text.length < 1) {
            setUploading(false);
            alert("Bitte füge Text hinzu.");
            return;
        }

        let processedFiles = null;
        if (uploadedFiles.length > 0) {
            processedFiles = await processFiles(uploadedFiles);
            console.log('Processed Files:', processedFiles);
        }

        const imageMedia = [];
        const videoMedia = [];
        const fileMedia = [];
        const voiceMedia = [];

        const wId = uuidv4();

        if (processedFiles) {
            for (const image of processedFiles.images) {
                const imageUrl = await uploadFileToFirebase(image, `internal/wiki/${wId}/images/`);
                imageMedia.push(imageUrl);
            }

            for (const video of processedFiles.videos) {
                const videoUrl = await uploadFileToFirebase(video.url, `internal/wiki/${wId}/videos/`);
                const thumbnailUrl = await uploadFileToFirebase(video.thumbnail, `internal/wiki/${wId}/thumbnails/`);
                videoMedia.push({
                    url: videoUrl,
                    thumbnail: thumbnailUrl,
                    duration: video.duration,
                    aspectRatio: video.aspectRatio,
                });
            }

            for (const audio of processedFiles.audios) {
                const audioUrl = await uploadFileToFirebase(audio.url, `internal/wiki/${wId}/voices/`);
                voiceMedia.push({
                    url: audioUrl,
                    duration: audio.duration,
                });
            }

            for (const file of processedFiles.others) {
                const fileUrl = await uploadFileToFirebase(file.url, `internal/wiki/${wId}/files/`);
                fileMedia.push({
                    filename: file.filename,
                    url: fileUrl,
                    size: file.size,
                    filetype: file.filetype,
                });
            }
        }

        const wiki = {
            id: wId,
            title: title,
            department: selectedWiki,
            textContent: JSON.stringify(editorContent.ops),
            imageMedia: imageMedia,
            videoMedia: videoMedia,
            fileMedia: fileMedia,
            voiceMedia: voiceMedia,
        };

        try {
            const result = await wikiService.postWikiContent(JSON.stringify(wiki));
            
            if (result === true) {
                showToast('Wiki-Content erfolgreich erstellt.', 'success');
                closeWikiCreate();
            } else {
                showToast('Wiki-Content nicht erstellt.', 'error');
            }
        } catch (error) {
            showToast('Wiki-Content nicht erstellt.', 'error');
            console.error('Error creating wiki content:', error.message);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="w-full flex-1 p-4">
            <Icon
                className="text-default-400 absolute top-4 right-4 cursor-pointer"
                icon="solar:close-square-bold"
                width={28}
                height={28}
                onClick={closeWikiCreate}
            />

            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    Wiki-Inhalt erstellen
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Erstelle einen Wiki-Inhalt
            </h2>

            <div className="mt-4 flex flex-col lg:flex-row gap-3">
                <div className="w-full lg:w-2/3">
                <Editor value={editorContent} onChange={handleEditorChange}></Editor>
                </div>
                <div className="w-full lg:w-1/3">
                    <Card className="rounded-lg border bg-background shadow p-2">
                        <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center cursor-pointer">
                            <input {...getInputProps()} />
                            <p className="text-gray-500">
                                Dateien reinziehen oder per Klick hochladen
                            </p>
                            <p className="text-xs text-gray-400">
                                (Alle Dateitypen erlaubt, max. 50 MB pro Datei)
                            </p>
                        </div>
                        {uploadedFiles.length > 0 && (
                            <div className="mt-4">
                                <h3 className="text-small text-default-500 mb-2">Anhänge:</h3>
                                <ul>
                                    {uploadedFiles.map((file, index) => (
                                        <li key={index} className="flex justify-between items-center text-small text-default-400">
                                            {file.name} ({(file.size / 1024).toFixed(2)} KB)
                                            <Icon
                                                className="text-default-400 cursor-pointer"
                                                icon="mdi:close"
                                                width={20}
                                                height={20}
                                                onClick={() => handleRemoveFile(file.name)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <Spacer y={2} />
                        <Input
                            label="Titel"
                            variant="underlined"
                            placeholder="Titel des Beitrags"
                            value={title}
                            onValueChange={setTitle}
                        />
                        <Spacer y={2} />
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Select
                                variant="underlined"
                                label="Wiki-Collection auswählen"
                                selectedKeys={[selectedWiki]}
                                onChange={handleWikiSelectionChange}
                                isInvalid={!!error}
                            >
                                {wikis.map((wiki) => (
                                    <SelectItem key={wiki.id}>
                                        {wiki.title}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}
                        <Spacer y={6} />
                        <div className="flex space-x-1">
                            <Button fullWidth={true} isLoading={uploading} onPress={handleWikiUpload} color="primary">
                                Wiki-Inhalt erstellen
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div >
    );
}
