class SocialMedia {
    constructor({
        facebook,
        linkedIn,
        instagram,
        tiktok,
        spotify,
    }) {
        this.facebook = facebook || null;
        this.linkedIn = linkedIn || null;
        this.instagram = instagram || null;
        this.tiktok = tiktok || null;
        this.spotify = spotify || null;
    }

    static fromJson(json) {
        return new SocialMedia(json);
    }
}

export default SocialMedia;