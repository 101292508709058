import PaymentPreferences from "./paymentPreferences";
import SocialMedia from "./socialMedia";

class LocalUser {
    constructor({
        id,
        mail,
        phone,
        position,
        surname,
        location,
        department,
        showBirthDate,
        dateOfEntry,
        dateOfBirth,
        firstname,
        name,
        isHeadOf,
        socialMedia = {},
        displayInCalendar,
        birthdayPreferences,
        paymentPreferences = {},
        latestRefresh,
        modules,
        avatarUrl,
    }) {
        this.id = id;
        this.mail = mail;
        this.phone = phone;
        this.position = position;
        this.surname = surname;
        this.location = location;
        this.department = department;
        this.showBirthDate = showBirthDate;
        this.dateOfEntry = dateOfEntry;
        this.dateOfBirth = dateOfBirth;
        this.firstname = firstname;
        this.name = name;
        this.isHeadOf = isHeadOf;
        this.socialMedia = new SocialMedia(socialMedia);
        this.displayInCalendar = displayInCalendar;
        this.birthdayPreferences = birthdayPreferences;
        this.paymentPreferences =  new PaymentPreferences(paymentPreferences);
        this.latestRefresh = latestRefresh;
        this.modules = modules;
        this.avatarUrl = avatarUrl;
    }

    static fromJson(json) {
        return new LocalUser(json);
    }
}

export default LocalUser;
