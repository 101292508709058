import React, { useState } from 'react';

const AppPromoImageUpload = ({ onImageChange }) => {
  const [image, setImage] = useState(null);

  const handleImageUpload = (file) => {
    const fileSize = file.size / 1024 / 1024;
    const validImageTypes = ['image/png', 'image/jpeg'];

    if (!validImageTypes.includes(file.type)) {
      alert('Nur PNG oder JPG / JPEG erlaubt!');
      return;
    }

    if (fileSize > 10) {
      alert('Die Datei muss kleiner als 10 MB sein!');
      return;
    }

    const imgUrl = URL.createObjectURL(file);
    setImage(imgUrl);
    onImageChange(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  const handleClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        handleImageUpload(file);
      }
    };
    input.click();
  };

  return (
    <div className="w-full justify-center">
      <div
        className="relative border border-gray-300 rounded-xl overflow-hidden max-w-xl"
        style={{ aspectRatio: '16 / 9' }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={handleClick}
      >
        {image ? (
          <img src={image} alt="Uploaded" className="w-full h-full object-cover" />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500 p-4">
            <p className='text-center'>Tippe hier, um ein Bild hochzuladen oder ziehe eines hierein. 16:9 Seitenverältnis und max. 10MB.</p>
          </div>
        )}
      </div>
    </div>
  );

};

export default AppPromoImageUpload;
