import React from "react";
import WaWiSearchComponent from "./WaWiSearchComponent";

function WaWiOverview() {
    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <WaWiSearchComponent />
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Zuletzt aufgerufen</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Hier werden Dir in Zukunft Deine 10 letzten Aufraufe angezeigt.
            </h2>
        </div>
    );
}

export default WaWiOverview;
