import React, { useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const GalleryComponent = ({ images, initialIndex, onClose }) => {
    const [index, setIndex] = useState(initialIndex);

    const closeLightbox = () => {
        setIndex(-1);
        onClose();
    };

    const galleryImages = images.map((image, i) => ({
        src: image.src,
        thumbnail: image.src,
        thumbnailWidth: image.width || 320,
        thumbnailHeight: image.height || 212,
        index: i
    }));

    return (
        <>
            <Gallery 
                images={galleryImages} 
                onClick={(event, { index }) => setIndex(index)} 
                enableImageSelection={false} 
            />
            {index >= 0 && (
                <Lightbox
                    open={index >= 0}
                    index={index}
                    close={closeLightbox}
                    slides={galleryImages.map(({ src }) => ({ src }))}
                    plugins={[Thumbnails]}
                />
            )}
        </>
    );
};

export default GalleryComponent;
