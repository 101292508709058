export const pushAudienceOptions = [
  { label: 'Alle Nutzer', value: 'allUsers', available: true },
  { label: 'Nur ausgeloggte Nutzer', value: 'loggedOutUsers', available: true },
  { label: 'Nur eingeloggte Nutzer', value: 'loggedInUsers', available: true },
  { label: 'Eingeloggt & mit Verkaufskorb', value: 'loggedInWithSellBasket', available: false },
  { label: 'Eingeloggt & mit Warenkorb', value: 'loggedInWithShoppingCart', available: false },
  { label: 'Benutzerdefiniert', value: 'custom', available: true },
];

export const pushRoutingOptions = [
  { label: 'App-Start', value: 'appStart', requireReference: false, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Shop Suche (Kategorie / mit Filtern)', value: 'buyCategory', requireReference: true, showArguments: true, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Shop Artikel', value: 'buyArticle', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Shop Warenkorb', value: 'buyBasket', requireReference: false, showArguments: false, excludeIf: ['allUsers', 'loggedOutUsers', 'loggedInWithSellBasket'] },
  { label: 'Shop Bestellung', value: 'buyOrder', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart', 'allUsers', 'loggedOutUsers', 'loggedInUsers'] },
  { label: 'Ankauf Artikel', value: 'sellArticle', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Ankauf Verkaufskorb', value: 'sellBasket', requireReference: false, showArguments: false, excludeIf: ['allUsers', 'loggedOutUsers', 'loggedInWithShoppingCart'] },
  { label: 'Ankauf Auftrag', value: 'sellOrder', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart', 'allUsers', 'loggedOutUsers', 'loggedInUsers'] },
  { label: 'Ankauf Artikelanfrage', value: 'articleRequest', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart', 'allUsers', 'loggedOutUsers', 'loggedInUsers'] },
  { label: 'Gewinnspiel', value: 'lottery', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Liveshow', value: 'liveshow', requireReference: true, showArguments: false, excludeIf: ['loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
];

export const pushTimeSettings = [
  { label: 'Sofort', value: 'now', excludeIf: ['allUsers', 'loggedInUsers', 'loggedInWithSellBasket', 'loggedInWithShoppingCart'] },
  { label: 'Einplanen', value: 'future', excludeIf: [] },
];

