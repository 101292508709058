import { Timestamp, doc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, uploadBytes, ref as storageRef, getStorage } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import db from '../firebaseConfig';
import Order from '../models/food/order';
import userService from './userService';
import OrderItem from '../models/food/orderItem';
import { FoodOffer } from '@/models/food/foodOffer';
import { getCookie } from '@/helpers/cookieHelper';

class FoodService {
  constructor() {
    this.foodOffersCollection = db.collection('foodoffers');
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
  }

  async getFoodOffers() {
    try {
      const authToken = getCookie('inteamuser');

      const response = await fetch(`${this.baseUrl}food?withUsers=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.bearerToken}`,
          'auth-token': authToken,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching posts: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !Array.isArray(data)) {
        throw new Error('Invalid response format: Expected an array of posts');
      }

      const offers = data.map(offerData => FoodOffer.fromJSON(offerData));

      return { offerData: offers };
    } catch (error) {
      console.error('Error fetching offers:', error.message);
      throw error;
    }
  }

  async updateOrderStatus(offerid, orderid, type) {
    try {
      const orderRef = db.collection("foodoffers").doc(offerid).collection('orders').doc(orderid);
      await updateDoc(orderRef, "status", type);
    } catch (error) {
      console.error('Error updating order status!', error.message);
    }
  }

  async getOfferById(id) {
    try {
      const querySnapshot = await db.collection('foodoffers').doc(id)
        .get();
  
      const order = querySnapshot.data();
  
      return order;
    } catch (error) {
        console.error('Error fetching orders:', error);
      throw error;
    }

  }

  async getOrdersById(id) {
    try {
      const querySnapshot = await db.collection('foodoffers').doc(id).collection('orders')
        .orderBy('created', 'asc')
        .get();

      const orders = [];
      querySnapshot.forEach(doc => {
        const orderData = { id: doc.id, ...doc.data() };
        orders.push(Order.fromJson(orderData));
      });

      return orders;
    } catch (error) {
        console.error('Error fetching orders:', error);
      throw error;
    }
  }

  async addOrder(offerid, elementCounter, elements) {
    var sum = 0;
    var i = 0;
    var orderItemsArr = [];
    var orderId = uuidv4();
    while (i < elementCounter) {
      sum += Number(elements[i+1].value);
      orderItemsArr.push(OrderItem.toArray({orderName: elements[i].value, sum: parseInt(elements[i+1].value)}));
      i = i + 2;
    }
    const inputs = {
      authorId: userService.getUser().id, 
        cancelled: null,
        created: {
          milliseconds: Date.now(),
        },
        id: orderId,
        items: orderItemsArr,
        paid: null,
        paymentConfirmed: null,
        status: "ordered",
        sum: sum,
    }
    try {
      const addedOrder = db.collection('foodoffers').doc(offerid).collection('orders').doc(orderId).set(inputs);
      if (addedOrder) {
          this.updateOrderCount(offerid);
      }
      console.log("Document written with ID: ", addedOrder.id);
    } catch (error) {
      console.error("Error adding document: ", error);
      console.error(userService.getUser().id, Timestamp.now(), orderItemsArr, sum);
    }
  } 

  updateOffer(offerid, field, value) {
    const offerRef = doc(db, "foodoffers", offerid);
    return updateDoc(offerRef, {[field]: value});
  }

  async uploadFile(file, docId) {
    const storage = getStorage();
    const fileUuid =  uuidv4();
    const fileRef = storageRef(storage, `internal/foodoffers/${docId}/${fileUuid}`);
    uploadBytes(fileRef, file)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref)
        .then((url) => {
          return url;
        })
        .catch((error) => {
          console.error("Couldn't get Download URL! ", error.message);
        });
    })
    .catch((error) => {
      console.error("Couldn't upload file! ", error.message);
    });
  }
  
  async addOffer(inputs) {
    const docId = uuidv4();
    var fileUrl = "";
    if (inputs.file) {
      fileUrl = await this.uploadFile(inputs.file, docId);
    }
    delete inputs.file;
    delete inputs.fileName;
    const id = userService.getUser().id;
    const splitOfferEnd = inputs.offerEnd.split(":");
    const splitEstDelivery = inputs.estDeliveryTime.split(":");
    var relevantDate = new Date();
    if (inputs.dateOfOffer === "tomorrow") {
      relevantDate = new Date(relevantDate.getTime() + 24*60*60000);
    }
    splitOfferEnd.push(relevantDate.getFullYear());
    splitOfferEnd.push(relevantDate.getMonth());
    splitOfferEnd.push(relevantDate.getDate());
    
    const convertedEstDeliveryTime = new Date(splitOfferEnd[2], splitOfferEnd[3], splitOfferEnd[4], splitEstDelivery[0], splitEstDelivery[1]).getTime();
    const convertedOfferEnd = new Date(splitOfferEnd[2], splitOfferEnd[3], splitOfferEnd[4], splitOfferEnd[0], splitOfferEnd[1]).getTime();
    inputs = {...inputs, orderEnd: { milliseconds: convertedOfferEnd}};
    inputs = {...inputs, estDeliveryTime: { milliseconds: convertedEstDeliveryTime}};
    inputs = {...inputs, dateOfOffer: { milliseconds: relevantDate.getTime()}};
    
    delete inputs.offerEnd;
    var newOffer = {
      authorId: id, 
      cancelled: null,
      cancellationReason: null,
      created: {
        milliseconds: Date.now(),
      },
      id: docId,
      imageUrl: fileUrl,
      orderSum: 0,
      orderCount: 0,
      orderMasterId: id,
      orders: [],
    };

    newOffer = {...newOffer, ...inputs};
    try {
      const docRef = await setDoc(doc(db, 'foodoffers', docId), newOffer);
      if (docRef) {
        return newOffer;
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      console.error(userService.getUser().id, Timestamp.now(), newOffer);
    }
  }

  async updateOrderCount(offerid) {
    const orderRef = doc(db, "foodoffers", offerid);
    updateDoc(orderRef, {orderCount: increment(1)});
  }
}

const foodService = new FoodService();

export default foodService;
