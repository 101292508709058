import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { Card, Spacer } from "@nextui-org/react";

const Editor = ({ value, onChange }) => {
    const handleChange = (content, delta, source, editor) => {
        const deltaContent = editor.getContents();
        onChange(deltaContent);
    };

    return (
        <div className="text-editor">
            <Card className="rounded-lg border bg-background shadow overflow-visible">
                <EditorToolbar />
            </Card>
            <Spacer y={2} />
            <Card className="rounded-lg border bg-background shadow overflow-visible min-h-96">
                <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={handleChange}
                    placeholder={"Beginne zu tippen..."}
                    modules={modules}
                    formats={formats}
                />
            </Card>
        </div>
    );
};

export default Editor;
