import { getCookie } from '../helpers/cookieHelper';

class MailService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
    }

    async sendMail(mailData) {
        try {
            const authToken = getCookie('inteamuser');

            const response = await fetch(`${this.baseUrl}mail/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(mailData),
            });

            if (response.ok) {
                return true; // Success
            } else {
                const errorResponse = await response.json();
                return { error: errorResponse.message || 'Fehler beim Senden.' };
            }
        } catch (e) {
            console.error('Error sending mail:', e);
            return { error: 'Ein unerwarteter Fehler ist aufgetreten.' };
        }
    }

    async addMail(mailData) {
        try {
            const authToken = getCookie('inteamuser');

            const response = await fetch(`${this.baseUrl}mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(mailData),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                if (responseData.id) {
                    return { success: true, id: responseData.id };
                }
                return { success: true };
            } else {
                const errorResponse = await response.json();
                return { error: errorResponse.message || 'Fehler beim Speichern.' };
            }
        } catch (e) {
            console.error('Error saving mail:', e);
            return { error: 'Ein unerwarteter Fehler ist aufgetreten.' };
        }
    }

    async updateMail(id, mailData) {
        try {
            const authToken = getCookie('inteamuser');

            const response = await fetch(`${this.baseUrl}mail/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(mailData),
            });

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.id) {
                    return { success: true, id: responseData.id };
                }
                return { success: true };
            } else {
                const errorResponse = await response.json();
                return { error: errorResponse.message || 'Fehler beim Speichern.' };
            }
        } catch (e) {
            console.error('Error saving mail:', e);
            return { error: 'Ein unerwarteter Fehler ist aufgetreten.' };
        }
    }

    async getMails() {
        try {
            const authToken = getCookie('inteamuser');

            const response = await fetch(`${this.baseUrl}mail`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching feed: ${response.statusText}`);
            }

            const data = await response.json();

            if (!data || !Array.isArray(data)) {
                throw new Error('Invalid response format: Expected an array of feed');
            }

            return { mails: data };
        } catch (error) {
            console.error('Error fetching feed:', error.message);
            throw error;
        }
    }

    async getMail(id) {
        try {
            const authToken = getCookie('inteamuser');

            const response = await fetch(`${this.baseUrl}mail/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching feed: ${response.statusText}`);
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('Error fetching feed:', error.message);
            throw error;
        }
    }
}

const mailService = new MailService();
export default mailService;