class VoiceFile {
    constructor({ url, duration }) {
        this.url = url;
        this.duration = duration;
    }

    static fromJson(json) {
        return new VoiceFile(json);
    }

    toJson() {
        return {
            url: this.url,
            duration: this.duration,
        };
    }
}

export { VoiceFile };