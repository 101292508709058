class OrderItem {
    constructor({
        orderName,
        sum,
    }) {
        this.orderName = orderName;
        this.sum = sum;
    }

    static fromJson(json) {
        return new OrderItem(json);
    }

    static toArray(orderitem) {
        return ({
            orderName: orderitem.orderName,
            sum: orderitem.sum,
        })
    }
}

export default OrderItem;