import React, { useEffect, useMemo, useState } from "react";
import WaWiSearchComponent from "./WaWiSearchComponent";
import { useNavigate } from "react-router-dom";
import { Spinner, Card, Accordion, AccordionItem, Image, Button, Select, Input, SelectItem } from "@nextui-org/react";
import wawiService from "@/services/wawiService";
import { Icon } from "@iconify/react/dist/iconify.js";
import userService from "@/services/userService";
import mapZoxsy from '../../assets/svg/zoxsy_location.svg'
import WaWiCustomerComponent from "./WaWiCustomerComponent";
import WaWiDixalistComponent from "./WaWiDixaListComponent";
import WaWiSellOrderArticleComponent from "./WaWiSellOrderArticle";

export const filterTypes = [
    { key: "highestPrice", label: "Höchster Wert" },
    { key: "highestOffer", label: "Höchstes Angebot" },
    { key: "highestDiscrepancy", label: "Höchste Abwertung" },
    { key: "alphabetical", label: "Alphabetisch" },
];

export const sortTypes = [
    { key: "asc", label: "Absteigend" },
    { key: "desc", label: "Aufsteigend" },
];

const itemClasses = {
    base: "py-0 w-full",
    trigger: "data-[hover=true]:bg-default-100 rounded-lg h-14 flex items-center",
};

function WaWiSellOrder({ id, type }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [sortType, setSortType] = React.useState("asc");
    const [filter, setFilter] = React.useState("highestPrice");
    const [value, setValue] = React.useState("");
    const hasAccessToCustomerData = userService.user.modules.includes(11);
    const hasAccessToDixaData = userService.user.modules.includes(11);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const { order: fetchedOrder } = await wawiService.getSellOrder(type, id);

                if (fetchedOrder) {
                    setOrder(fetchedOrder);
                } else {
                    throw new Error("Order not found or empty response.");
                }
            } catch (error) {
                console.error("Error fetching order data:", error);
                setError(error.message || "An unknown error occurred.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, type]);

    const sortArticles = useMemo(() => (articles) => {
        const sortOrder = sortType === "asc" ? 1 : -1;
        switch (filter) {
            case "highestPrice":
                return articles.sort((a, b) => (b.sumOrigin - a.sumOrigin) * sortOrder);
            case "highestOffer":
                return articles.sort((a, b) => (b.sumNew - a.sumNew) * sortOrder);
            case "highestDiscrepancy":
                return articles.sort(
                    (a, b) => ((b.sumOrigin - b.sumNew) - (a.sumOrigin - a.sumNew)) * sortOrder
                );
            case "alphabetical":
                return articles.sort((a, b) => a.title.localeCompare(b.title) * sortOrder);
            default:
                return articles;
        }
    }, [sortType, filter]);

    const filterArticles = useMemo(() => {
        if (!value) return order?.articles || [];

        return order?.articles.filter((article) => {
            const titleMatch = article.title.toLowerCase().includes(value.toLowerCase());
            const conditionMatch = article.conditionOrigin.toLowerCase().includes(value.toLowerCase());
            return titleMatch || conditionMatch;
        });
    }, [value, order]);

    const sortedAndFilteredArticles = useMemo(() => {
        if (!order?.articles) return [];
        const filteredArticles = filterArticles;
        return sortArticles(filteredArticles);
    }, [filterArticles, sortArticles, order?.articles]);

    return (
        <div className="w-full h-full flex-1 p-4" style={{ overflowY: "auto" }}>
            <WaWiSearchComponent presetId={id} presetType={type} />
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">
                    Ankaufauftrag #{order?.orderId || ''}
                </h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Statusdaten sind synchron zur Anzeige im Kundencenter.
            </h2>
            {isLoading ? (
                <div className="flex justify-center">
                    <Spinner size="large" />
                </div>
            ) : error ? (
                <Card className="w-full rounded-lg border bg-background p-4 shadow">
                    <h3 className="text-red-600 font-bold text-xl">Ups</h3>
                    <p className="text-red-500">{error}</p>
                </Card>
            ) : order ? (
                <div className="flex flex-col lg:flex-row lg:gap-4 lg:order-first">
                    <div className="flex-grow lg:flex-grow-0 lg:w-2/3 space-y-4">
                        <Accordion
                            isCompact
                            showDivider={false}
                            hideIndicator
                            className="rounded-lg border bg-background p-4 shadow"
                            variant="shadow"
                            itemClasses={itemClasses}
                            defaultExpandedKeys={["orderdetails"]}
                            disallowEmptySelection={true}
                        >
                            <AccordionItem
                                key="orderdetails"
                                aria-label="Auftragsdetails"
                                subtitle="Allgemeine Infos"
                                title="Auftragsdetails"
                            >
                                <p className="text-small text-default-500">Aktueller Status: {order.status}</p>
                                <p className="text-small text-default-500">Erstellt: {order.created}</p>
                                <p className="text-small text-default-500">Voraussichtliche Bearbeitung: {order.estimatedProcessingDate}</p>
                                <p className="text-small text-default-500">Premium?: {order.premiumFee}</p>
                                <p className="text-small text-default-500">ZOXS CHECK?: {order.zoxsCheck ? "Ja" : "Nein"}</p>
                                <p className="text-small text-default-500">Auszahlungsart: {order.payout.toString()}</p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isCompact
                            showDivider={false}
                            hideIndicator
                            className="rounded-lg border bg-background p-4 shadow"
                            variant="shadow"
                            itemClasses={itemClasses}
                            defaultExpandedKeys={["orderarticles"]}
                            disallowEmptySelection={true}
                        >
                            <AccordionItem
                                key="orderarticles"
                                aria-label="Artikel"
                                subtitle="Zum Verkauf angebotene Artikel und Pürfergebnisse"
                                title="Artikel"
                            >
                                <>
                                    <div className="flex flex-col md:flex-row md:space-x-2 w-full items-center pt-4 pb-4">
                                        <div className="w-full md:w-1/2">
                                            <Input
                                                fullWidth
                                                label="Artikel / Zustand"
                                                placeholder="Suche nach einem Artikel oder Zustand..."
                                                value={value}
                                                variant="bordered"
                                                size="sm"
                                                onValueChange={setValue}
                                            />
                                        </div>
                                        <div className="w-full md:w-1/4">
                                            <Select
                                                fullWidth
                                                disallowEmptySelection
                                                label="Filter"
                                                variant="bordered"
                                                size="sm"
                                                placeholder="Filtern nach..."
                                                selectedKeys={[filter]}
                                                onChange={(e) => setFilter(e.target.value)}
                                            >
                                                {filterTypes.map((type) => (
                                                    <SelectItem key={type.key}>{type.label}</SelectItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="w-full md:w-1/4">
                                            <Select
                                                fullWidth
                                                disallowEmptySelection
                                                label="Sortieren"
                                                variant="bordered"
                                                size="sm"
                                                placeholder="Sortieren nach..."
                                                selectedKeys={[sortType]}
                                                onChange={(e) => setSortType(e.target.value)}
                                            >
                                                {sortTypes.map((type) => (
                                                    <SelectItem key={type.key}>{type.label}</SelectItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <Accordion
                                        isCompact
                                        showDivider={false}
                                        itemClasses={itemClasses}
                                    >
                                        {sortedAndFilteredArticles.map((article) => (
                                            <AccordionItem
                                                key={article.id}
                                                aria-label={article.title}
                                                title={article.title}
                                                subtitle={`${article.conditionOrigin} (Ursprung)`}
                                                startContent={
                                                    <Image
                                                        width={50}
                                                        height={50}
                                                        radius="none"
                                                        alt={article.title}
                                                        src={article.img}
                                                        className="object-scale-down"
                                                    />
                                                }
                                            >
                                                <WaWiSellOrderArticleComponent article={article}></WaWiSellOrderArticleComponent>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="flex-grow lg:flex-grow-0 lg:w-1/3 space-y-4">
                        <Accordion
                            isCompact
                            selectionMode="multiple"
                            showDivider={false}
                            className="rounded-lg border bg-background p-4 shadow"
                            variant="shadow"
                            itemClasses={itemClasses}
                        >
                            <AccordionItem
                                key="customer"
                                aria-label="Kundendaten"
                                startContent={<Icon
                                    className="text-default-500"
                                    icon="solar:user-id-outline"
                                    width={24}
                                />}
                                subtitle="Stammdaten aus dem Kundencenter"
                                title="Kundendaten"
                            >
                                {
                                    hasAccessToCustomerData ? (
                                        <>
                                            <div className="pt-4 pb-4">
                                                <WaWiCustomerComponent type={type} id={id} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className="pt-4 pb-4 text-center" css={{ maxWidth: '500px', textAlign: 'center' }}>
                                            <div className="flex justify-center mb-4">
                                                <Image
                                                    width={150}
                                                    alt="Map ZOXSY"
                                                    src={mapZoxsy}
                                                />
                                            </div>
                                            <h2 className="text-xl font-bold mb-2">Ups - Du hast Dich wohl verlaufen.</h2>
                                            <p className="text-default-500 mb-6">Für diese Aktion hast Du keine Berechtigung.</p>
                                            <div className="flex flex-col gap-3">
                                                <Button color="secondary" auto>
                                                    Recht anfragen
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </AccordionItem>
                            <AccordionItem
                                key="notes"
                                aria-label="Interne Notizen"
                                startContent={<Icon
                                    className="text-default-500"
                                    icon="solar:document-add-outline"
                                    width={24}
                                />}
                                subtitle={`${order.internalNotes.length} interne Notizen`}
                                title="Interne Notizen"
                            >
                                <div className="pt-4 pb-4 px-3">{order.internalNotes?.length > 0 ? (
                                    order.internalNotes.map((note, index) => (
                                        <div key={index}>
                                            <div
                                                className="text-small text-default-500"
                                                dangerouslySetInnerHTML={{
                                                    __html: note.replace(/(<br\s*\/?>|\r?\n)+$/g, ""),
                                                }}
                                            />
                                            {index < order.internalNotes.length - 1 && <hr className="my-4" />}
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-small text-default-500">Keine Notizen verfügbar</p>
                                )}</div>
                            </AccordionItem>
                            <AccordionItem
                                key="dixa"
                                aria-label="Dixa-Fälle"
                                startContent={<Icon
                                    className="text-default-500"
                                    icon="solar:mailbox-outline"
                                    width={24}
                                />}
                                subtitle="Auftrag zugeordnete Dixa-Konversationen"
                                title="Dixa-Fälle"
                            >
                                {
                                    hasAccessToDixaData ? (
                                        <>
                                            <WaWiDixalistComponent query={order.orderId} />
                                        </>
                                    ) : (
                                        <div className="pt-4 text-center" css={{ maxWidth: '500px', textAlign: 'center' }}>
                                            <div className="flex justify-center mb-4">
                                                <Image
                                                    width={150}
                                                    alt="Map ZOXSY"
                                                    src={mapZoxsy}
                                                />
                                            </div>
                                            <h2 className="text-xl font-bold mb-2">Ups - Du hast Dich wohl verlaufen.</h2>
                                            <p className="text-default-500 mb-6">Für diese Aktion hast Du keine Berechtigung.</p>
                                            <div className="flex flex-col gap-3">
                                                <Button color="secondary" auto>
                                                    Recht anfragen
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

            ) : (
                <p className="text-default-500">Keine Auftragsdaten verfügbar.</p>
            )}
        </div>
    );
}

export default WaWiSellOrder;