import { getEmojiByEmojiId, getIdByEmoji } from "../../helpers/emojiMap";
import { v4 as uuidv4 } from 'uuid';

class Reaction {
    constructor({
        id = uuidv4(),
        emoji,
        author,
        created,
    }) {
        this.id = id;
        this.author = author;

        if (typeof emoji === 'string') {
            this.emoji = emoji;
        } else {
            this.emoji = this.parseEmoji(emoji);
        }

        if (created instanceof Date) {
            this.created = created;
        } else if (
            created &&
            typeof created._seconds === 'number' &&
            typeof created._nanoseconds === 'number'
        ) {
            const timestamp = created._seconds * 1000 + created._nanoseconds / 1000000;
            this.created = new Date(timestamp);
        } else {
            console.warn('Malformed created field:', created);
            this.created = null;
        }
    }

    parseEmoji(emojiId) {
        if (typeof emojiId === 'undefined') {
            console.warn(`Invalid emoji ID: ${emojiId}, defaulting to ❓`);
            return '❓';
        }

        const emoji = getEmojiByEmojiId(emojiId);
        if (emoji === '❓') {
            console.warn(`Invalid emoji ID: ${emojiId}, using ❓`);
        }
        return emoji;
    }

    static fromJson(json) {
        return new Reaction({
            id: json.id,
            emoji: json.emoji,
            author: json.author,
            created: json.created ? json.created : null,
        });
    }
}

export { Reaction };
