import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Image, Spacer, Spinner } from '@nextui-org/react';
import { getCookie, setCookie } from '@/helpers/cookieHelper';

function AuthMiddleware({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const authToken = getCookie('inteamuser');
    const urlParams = new URLSearchParams(location.search);
    const cred = urlParams.get('cred');

    if (!authToken && cred) {
      fetch(`${process.env.REACT_APP_BASE_URL}user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          'auth-token': cred,
        },
      })
        .then(response => {
          if (response.status === 200) {
            setCookie('inteamuser', cred);
            setIsAuthenticated(true);

            urlParams.delete('cred');
            navigate({
              pathname: location.pathname,
              search: urlParams.toString(),
            }, { replace: true });
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch(error => {
          console.error('Authentication error with cred:', error);
          setIsAuthenticated(false);
        });
      return;
    }

    if (!authToken) {
      setIsAuthenticated(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BASE_URL}user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        'auth-token': authToken,
      },
    })
      .then(response => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(error => {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
      });
  }, [location.search]);

  if (isAuthenticated === null) {
    return (
      <div className="relative flex h-screen w-screen">
        <div className="absolute left-2 top-5 lg:left-5">
          <div className="flex items-center">
            <Image
              height={20}
              alt="ZOXSY ZZ"
              src="https://www.sx-oz.de/assets/img/logos/ZZ-Logo_ZOXSY.svg"
            />
            <Spacer x={2} />
            <p className="font-medium">inteam</p>
          </div>
        </div>

        <div className="flex w-full items-center justify-center bg-background lg:w-1/2">
          <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
            <Spinner />
          </div>
        </div>

        <div
          className="relative hidden w-1/2 flex-col-reverse rounded-medium p-10 shadow-small lg:flex"
          style={{
            backgroundImage:
              "url(https://www.zoxs.org/wp-content/uploads/2021/06/ZOXS-Hauptgebaeude-Wesel.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    );
  } else if (!isAuthenticated) {
    return (
      <Navigate
        to={`/auth?redirect=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`}
      />
    );
  } else {
    return children;
  }
}

export default AuthMiddleware;
