import { Button, Card, CardFooter } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";

const AppPushItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Card className="w-full rounded-lg border bg-background shadow">
      <div className="feed-item p-4">
        <div className="flex items-center gap-4">
          {item.imageUrl && (
            <img
              src={item.imageUrl}
              alt={item.title}
              className="h-16 object-cover rounded"
            />
          )}
          <div>
            <h3 className="text-xl font-semibold">{(item.title && item.title !== 'null') ? item.title : 'Kein Titel'}</h3>
            <p className="text-gray-600">{(item.message && item.message !== 'null') ? item.message : 'Keine Nachricht'}</p>
          </div>
        </div>
        <div className="mt-2 text-gray-500">
          <span>Aktiv ab: {item.start ? item.start.toLocaleString('de-DE') : 'N/A'}</span>
          {/* <span>Erstellt am: {new Date(item.created).toLocaleDateString()}</span> |{" "}
          <span>Erstellt von: {item.createdBy}</span> |{" "}
          <span>Aktualisiert am: {new Date(item.updated).toLocaleDateString()}</span> |{" "}
          <span>Aktualisiert von: {item.updatedBy}</span> */}
        </div>
      </div>
    </Card>
  );
};

export default AppPushItem;  