import { AppUser } from "../user/appUser";
import StoryItem from "./storyItem";

class Story {
    constructor({
        authorId,
        user,
        storyItems = [],
    }) {
        try {
            this.authorId = authorId;
            this.user = user instanceof AppUser ? user : new AppUser(user);
            this.storyItems = Array.isArray(storyItems)
                ? storyItems.map((item) =>
                      item instanceof StoryItem ? item : new StoryItem(item)
                  )
                : [];
        } catch (error) {
            console.error("Error in Story constructor:", error);
        }
    }

    static fromJson(json) {
        try {
            console.log("JSON data in fromJson:", json);
            return new Story({
                ...json,
                user: new AppUser(json.user),
                storyItems: json.storyItems
                    ? json.storyItems.map((item) => StoryItem.fromJson(item))
                    : [],
            });
        } catch (error) {
            console.error("Error in fromJson method:", error);
            return null;
        }
    }
}

export default Story;