import { getCookie } from '../helpers/cookieHelper';

class WaWiService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.bearerToken = process.env.REACT_APP_BEARER_TOKEN;
    }

    async getSellOrder(type, id) {
        try {
            const authToken = getCookie('inteamuser');
            const requestBody = {
                email: "",
                phone: "",
                customerId: "",
                sellOrderId: "",
                buyOrderId: "",
                sn: "",
            };
            if (type === 'orderId') {
                requestBody.sellOrderId = id;
            } else if (type in requestBody) {
                requestBody[type] = id;
            } else {
                throw new Error(`Invalid type: ${type}`);
            }

            const response = await fetch(`${this.baseUrl}zz/sellOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }

            const data = await response.json();

            if (!data || !Array.isArray(data)) {
                throw new Error('Invalid response format: Expected an array of orders');
            }

            return { order: data[0] };
        } catch (error) {
            console.error('Error fetching sell order:', error.message);
            return { error: error.message };
        }
    }

    async getBuyOrder(type, id) {
        try {
            const authToken = getCookie('inteamuser');
            const requestBody = {
                email: "",
                phone: "",
                customerId: "",
                sellOrderId: "",
                buyOrderId: "",
                sn: "",
            };
            if (type === 'orderId') {
                requestBody.buyOrderId = id;
            } else if (type in requestBody) {
                requestBody[type] = id;
            } else {
                throw new Error(`Invalid type: ${type}`);
            }

            const response = await fetch(`${this.baseUrl}zz/buyOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }

            const data = await response.json();

            if (!data || !Array.isArray(data)) {
                throw new Error('Invalid response format: Expected an array of orders');
            }

            return { order: data[0] };
        } catch (error) {
            console.error('Error fetching buy order:', error.message);
            return { error: error.message };
        }
    }

    async getCustomer(type, id) {
        try {
            const authToken = getCookie('inteamuser');
            const requestBody = {
                email: "",
                phone: "",
                customerId: "",
                sellOrderId: "",
                buyOrderId: "",
                sn: "",
            };
            if (type === 'orderId') {
                requestBody.sellOrderId = id;
            } else if (type in requestBody) {
                requestBody[type] = id;
            } else {
                throw new Error(`Invalid type: ${type}`);
            }

            const response = await fetch(`${this.baseUrl}zz/customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }

            const data = await response.json();

            return { customer: data };
        } catch (error) {
            console.error('Error fetching customer:', error.message);
            return { error: error.message };
        }
    }

    async getDixaConversations(query) {
        try {
            const authToken = getCookie('inteamuser');
            const response = await fetch(`${this.baseUrl}zz/dixa/search/${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }

            const data = await response.json();

            return { conversations: data };
        } catch (error) {
            console.error('Error fetching dixa conversations:', error.message);
            return { error: error.message };
        }
    }

    async getDixaConversationById(id) {
        try {
            const authToken = getCookie('inteamuser');
            const response = await fetch(`${this.baseUrl}zz/dixa/conversation/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.bearerToken}`,
                    'auth-token': authToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }

            const data = await response.json();

            return { conversation: data };
        } catch (error) {
            console.error('Error fetching dixa conversation:', error.message);
            return { error: error.message };
        }
    }
}

const wawiService = new WaWiService();
export default wawiService;
