import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/newsapp.css';
import App from './App';
import { NextUIProvider } from "@nextui-org/system";
import ErrorBoundary from './ErrorBoundary';

window.addEventListener("error", (e) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        e.stopImmediatePropagation();
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <NextUIProvider locale="de-DE">
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </NextUIProvider>
);
