class PaymentPreferences {
    constructor({ cashOnly, cashAccepted, payPalMail, payPal }) {
        this.cashOnly = cashOnly;
        this.cashAccepted = cashAccepted;
        this.payPalMail = payPalMail;
        this.payPal = payPal;
    }

    static fromJson(json) {
        return new PaymentPreferences(json);
    }
}

export default PaymentPreferences;