import React, { createContext, useContext, useState, useCallback } from "react";
import { domAnimation, LazyMotion, m } from "framer-motion";
import AppPushCreatorNavigationButtons from "./AppPushCreatorNavigationButtons";
import AppPushCreatorContent from "./AppPushCreatorContent";
import AppPushCreatorTarget from "./AppPushCreatorTarget";
import AppPushCreatorNavigation from "./AppPushCreatorNavigation";
import AppPushCreatorOverview from "./AppPushCreatorOverview";
import AppPushCreatorType from "./AppPushCreatorType";
import AppPushCreatorTime from "./AppPushCreatorTime";
import useToast from '@/hooks/useToast';
import { pushRoutingOptions, pushTimeSettings } from "./types";
import { parseZonedDateTime, parseAbsoluteToLocal } from "@internationalized/date";
import zoxsManagementService from "@/services/zoxsManagementService";
import { useNavigate } from "react-router-dom";

const AppPushCreatorContext = createContext();

export const useAppPushCreator = () => useContext(AppPushCreatorContext);

const variants = {
    enter: (direction) => ({
        y: direction > 0 ? 30 : -30,
        opacity: 0,
    }),
    center: {
        zIndex: 1,
        y: 0,
        opacity: 1,
    },
    exit: (direction) => ({
        zIndex: 0,
        y: direction < 0 ? 30 : -30,
        opacity: 0,
    }),
};

export default function AppPushCreator() {
    const { showToast } = useToast();
    const navigate = useNavigate();
    const [state, setState] = useState({
        image: null,
        title: "",
        message: "",
        audience: null,
        userIds: null,
        time: new Date(),
        route: null,
        reference: null,
        planned: null,
        args: null,
        json: {},

        titleError: false,
        messageError: false,
        audienceError: false,
        userIdsError: false,
        referenceError: false,
        argsError: false,

        isSaving: false,
    });

    const updateField = useCallback((field, value) => {
        setState((prev) => {
            const newState = { ...prev, [field]: value };
            generateJson(newState);
            return newState;
        });
    }, []);

    const generateJson = useCallback((currentState) => {
        const { title, message, audience, userIds, time, route, reference, planned, args } = currentState;

        let adjustedArgs = null;
        if (args != null) {
            try {
                adjustedArgs = JSON.parse(args);
            } catch (error) {
                adjustedArgs = {};
            }
        }

        const newJson = {
            id: null,
            title,
            message,
            type: 'promotion',
            audience,
            userIds: userIds ? userIds.split(",").map(Number) : null,
            start: new Date(time).toISOString(),
            referenceType: route,
            referenceId: reference || null,
            route: null,
            args: adjustedArgs || null,
            imageUrl: null,
        };
        setState((prev) => ({ ...prev, json: newJson }));
    }, []);

    const validateFields = useCallback(() => {
        const routeOption = pushRoutingOptions.find((option) => option.value === state.route);
        const timeSetting = pushTimeSettings.find((option) => option.value === state.planned);
        let argsError = false;
        if (routeOption && routeOption.showArguments && state.args) {
            try {
                JSON.parse(state.args);
            } catch (e) {
                argsError = true;
            }
        }

        const errors = {
            titleError: state.title.trim().length < 5,
            messageError: state.message.trim().length < 15,
            audienceError: state.audience === "custom" && (!state.userIds || !state.userIds.trim()),
            routeError: state.route === null || (routeOption && routeOption.excludeIf.includes(state.audience)),
            referenceError: routeOption && routeOption.requireReference && state.reference === null,
            plannedError: state.planned === null || (timeSetting && timeSetting.excludeIf.includes(state.audience)),
            timeError: state.planned === "future" && (!state.time || new Date(state.time) < new Date(Date.now() + 15 * 60 * 1000)),
            argsError: argsError,
        };

        setState((prev) => ({ ...prev, ...errors }));
        return !Object.values(errors).includes(true);
    }, [state]);

    const onSave = useCallback(async () => {
        if (!validateFields()) return;

        try {
            setState((prev) => ({ ...prev, isSaving: true }));
            const formData = new FormData();

            if (state.image) {
                formData.append("image", state.image);
            }

            Object.keys(state.json).forEach((key) => {
                const value = state.json[key];

                if (value == null) {
                    return;
                }

                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        if (item != null) {
                            formData.append(`${key}[]`, item);
                        }
                    });
                } else {
                    formData.append(key, value);
                }
            });

            console.log(state.image);

            await zoxsManagementService.createPush(formData);
            showToast('Push erfolgreich erstellt.', 'success');
            navigate('/admin-push');
        } catch (error) {
            showToast('Ein Fehler ist aufgetreten: ' + error.message, 'error');
        } finally {
            setState((prev) => ({ ...prev, isSaving: false }));
        }
    }, [validateFields, state]);

    const [[page, direction], setPage] = useState([0, 0]);

    const validatePageChange = useCallback((newPage) => {
        const routeOption = pushRoutingOptions.find((option) => option.value === state.route);
        const timeSetting = pushTimeSettings.find((option) => option.value === state.planned);
        let argsError = false;
        if (routeOption && routeOption.showArguments && state.args) {
            try {
                JSON.parse(state.args);
            } catch (e) {
                argsError = true;
            }
        }

        if (newPage === 1 && (state.title.trim().length < 5 || state.message.trim().length < 15)) {
            if (state.title.trim().length < 5) {
                updateField("titleError", true);
                showToast('Der Titel muss mindestens 5 Zeichen haben.', 'error');
            }
            if (state.message.trim().length < 15) {
                updateField("messageError", true);
                showToast('Die Nachricht muss mindestens 15 Zeichen haben.', 'error');
            }

            return false;
        }
        if (newPage === 2 && (!state.audience || (state.audience === "custom" && (!state.userIds || !state.userIds.trim())))) {
            if (!state.audience) {
                showToast('Bitte wähle eine Zielgruppe aus.', 'error');
            }
            if (state.audience === "custom" && (!state.userIds || !state.userIds.trim())) {
                updateField("userIdsError", true);
                showToast('Bitte füge mindestens einen Kunden hinzu.', 'error');
            }
            return false;
        }
        if (newPage === 3 && (!state.route || (routeOption && routeOption.excludeIf.includes(state.audience)) || (routeOption && routeOption.requireReference && state.reference === null) || argsError)) {
            if (!state.route) {
                showToast('Bitte wähle ein App-Ziel aus.', 'error');
            }
            if (routeOption && routeOption.excludeIf.includes(state.audience)) {
                showToast('Bitte wähle ein mit Deiner Zielgruppe kompatibles Appziel aus.', 'error');
            }
            if (routeOption && routeOption.requireReference && state.reference === null) {
                updateField("referenceError", true);
                showToast('Bitte hinterlege eine Zielreferenz.', 'error');
            }
            if (argsError) {
                updateField("argsError", true);
                showToast('Die Argumente müssen gültiges JSON sein.', 'error');
            }
            return false;
        }
        if (newPage === 4) {
            if (!state.planned || (timeSetting && timeSetting.excludeIf.includes(state.audience))) {
                if (!state.planned) {
                    showToast('Bitte wähle eine Terminoption aus.', 'error');
                } else if (timeSetting && timeSetting.excludeIf.includes(state.audience)) {
                    showToast('Die gewählte Terminoption ist für die Zielgruppe nicht verfügbar.', 'error');
                }
                return false;
            }

            if (state.planned === "future" && (!state.time || new Date(state.time) < new Date(Date.now() + 15 * 60 * 1000))) {
                if (!state.time) {
                    showToast('Bitte wähle eine Uhrzeit aus.', 'error');
                } else if (new Date(state.time) < new Date(Date.now() + 15 * 60 * 1000)) {
                    showToast('Die geplante Zeit muss mindestens 15 Minuten in der Zukunft liegen.', 'error');
                }
                return false;
            }
        }
        return true;
    }, [state]);

    const paginate = useCallback((newDirection) => {
        setPage((prev) => {
            const nextPage = prev[0] + newDirection;

            if (nextPage < 0 || nextPage > 5 || !validatePageChange(nextPage)) return prev;

            return [nextPage, newDirection];
        });
    }, [validatePageChange]);

    const onChangePage = useCallback((newPage) => {
        if (!validatePageChange(newPage)) return;

        setPage((prev) => {
            if (newPage < 0 || newPage > 5) return prev;
            const currentPage = prev[0];

            return [newPage, newPage > currentPage ? 1 : -1];
        });
    }, [validatePageChange]);

    const onBack = useCallback(() => {
        paginate(-1);
    }, [paginate]);

    const onNext = useCallback(() => {
        paginate(1);
    }, [paginate]);

    const content = React.useMemo(() => {
        let component = <AppPushCreatorContent />;

        switch (page) {
            case 1:
                component = <AppPushCreatorTarget />;
                break;
            case 2:
                component = <AppPushCreatorType />;
                break;
            case 3:
                component = <AppPushCreatorTime />;
                break;
            case 4:
                component = <AppPushCreatorOverview />;
                break;
        }

        return (
            <LazyMotion features={domAnimation}>
                <m.div
                    key={page}
                    animate="center"
                    className="col-span-full"
                    custom={direction}
                    exit="exit"
                    initial="exit"
                    transition={{
                        y: {
                            ease: "backOut",
                            duration: 0.35,
                        },
                        opacity: { duration: 0.4 },
                    }}
                    variants={variants}
                >
                    {component}
                </m.div>
            </LazyMotion>
        );
    }, [direction, page]);

    return (
        <AppPushCreatorContext.Provider value={{ state, updateField, onSave }}>
            <AppPushCreatorNavigation
                currentPage={page}
                onBack={onBack}
                onChangePage={onChangePage}
                onNext={onNext}
            >
                <div className="flex h-full w-full flex-col">
                    <div className="relative flex h-full flex-col overflow-y-auto pt-6 text-center lg:pt-0">
                        {content}
                        <AppPushCreatorNavigationButtons
                            backButtonProps={{ isDisabled: page === 0 }}
                            className="hidden justify-start lg:flex"
                            nextButtonProps={{
                                children: page === 4 ? "Push-Kampagne starten" : "Weiter",
                            }}
                            onBack={onBack}
                            onNext={page === 4 ? onSave : onNext}
                        />
                    </div>
                </div>
            </AppPushCreatorNavigation>
        </AppPushCreatorContext.Provider>
    );
}
