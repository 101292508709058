import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';

const AppFeedbackPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    const [activeComponent, setActiveComponent] = useState('AppFeedback');
    const userHasPermission = userService.user.modules.includes(5);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/app-feedback/') && id) {
            setActiveComponent('AppFeedbackDetail');
        } else {
            setActiveComponent('AppFeedback');
        }
    }, [location.pathname, id]);

    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
        {activeComponent === 'AppFeedback' && <div></div>}
        {activeComponent === 'AppFeedbackDetail' && <div></div>}
            {/* {activeComponent === 'AppFeedback' && <AppFeedback />}
            {activeComponent === 'AppFeedbackDetail' && <AppFeedbackDetail id={id} />} */}
        </>
    );
};

export default AppFeedbackPage;
