class Order {
    constructor({
        authorId,
        cancelled,
        created,
        id,
        items,
        orderName,
        paid,
        paymentConfirmed,
        sum,
        status,
    }) {
        this.authorId = authorId;
        this.cancelled = cancelled;
        this.created = created;
        this.id = id;
        this.items = items;
        this.orderName = orderName;
        this.paymentConfirmed = paymentConfirmed;
        this.paid = paid;
        this.sum = sum;
        this.status = status;
    }

    static fromJson(json) {
        return new Order(json);
    }
}

export default Order;