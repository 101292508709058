import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie } from '../helpers/cookieHelper';
import { Image, Spacer, Spinner } from '@nextui-org/react';

function AntiAuthMiddleware({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const authToken = getCookie('inteamuser');
    if (!authToken) {
      setIsAuthenticated(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BASE_URL}user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        'auth-token': authToken,
      },
    })
      .then(response => {
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(error => {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
      });
  }, []);

  if (isAuthenticated === null) {
    return <div className="relative flex h-screen w-screen">
      <div className="absolute left-2 top-5 lg:left-5">
        <div className="flex items-center">
          <Image
            height={20}
            alt="ZOXSY ZZ"
            src="https://www.sx-oz.de/assets/img/logos/ZZ-Logo_ZOXSY.svg"
          />
          <Spacer x={2} />
          <p className="font-medium">inteam</p>
        </div>
      </div>

      <div className="flex w-full items-center justify-center bg-background lg:w-1/2">
        <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
          <Spinner></Spinner>
        </div>
      </div>

      <div
        className="relative hidden w-1/2 flex-col-reverse rounded-medium p-10 shadow-small lg:flex"
        style={{
          backgroundImage:
            "url(https://www.zoxs.org/wp-content/uploads/2021/06/ZOXS-Hauptgebaeude-Wesel.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
      </div>
    </div>;
  } else if (isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default AntiAuthMiddleware;
