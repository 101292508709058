import { Timestamp, increment } from 'firebase/firestore';
import db from '../firebaseConfig';
import feedService from './feedService';
import userService from './userService';
import { v4 as uuidv4 } from 'uuid';

class CommentService {
    async addComment(postId, text) {
        var commentId = uuidv4();
        const commentObj = {
            id: commentId,
            comment: text,
            author: userService.getUser().id,
            created: Timestamp.now(),
            answerCount: 0,
            answers: [],
            deleted: false,
            isAnswer: false,
            reactions: [],
        }
        try {
          const addedComment = db.collection('posts').doc(postId).collection('comments').doc(commentId).set(commentObj);
          if (addedComment) {
              feedService.updatePost(postId, "commentCount", increment(1));
          }
          console.log("Document written with ID: ", addedComment.id);
        } catch (error) {
          console.error("Error adding comment: ", error);
          console.error(userService.getUser().id, Timestamp.now(), );
        }
    }
}

const commentService = new CommentService();

export default commentService;
