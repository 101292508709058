import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import userService from '@/services/userService';
import NoPermission from '@/components/NoPermission';
import WaWiOverview from './WaWiOverview';
import WaWiSellOrder from './WaWiSellOrder';
import WaWiBuyOrder from './WaWiBuyOrder';

const WaWiPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const type = params.get('type');

    const [activeComponent, setActiveComponent] = useState('WaWiOverview');
    const userHasPermission =
        userService.user.modules.includes(9) || userService.user.modules.includes(10);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/admin-wawi/sellOrder') && type && id) {
            setActiveComponent('WaWiSellOrder');
        } else if (path.startsWith('/admin-wawi/buyOrder') && type && id) {
            setActiveComponent('WaWiBuyOrder');
        } else {
            setActiveComponent('WaWiOverview');
        }
    }, [location.pathname, type, id]);


    if (!userHasPermission) {
        return <NoPermission />;
    }

    return (
        <>
            {activeComponent === 'WaWiOverview' && <WaWiOverview />}
            {activeComponent === 'WaWiSellOrder' && (
                <WaWiSellOrder id={id} type={type} />
            )}
            {activeComponent === 'WaWiBuyOrder' && (
                <WaWiBuyOrder id={id} type={type} />
            )}
        </>
    );
};

export default WaWiPage;
