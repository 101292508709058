"use client";

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Tabs,
  Tab,
  ScrollShadow,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import notificationService from "@/services/notificationService";
import NotificationItem from "./NotificationItem";

const NotificationTabs = {
  All: "all",
  Unread: "unread",
};

export default function NotificationComponent({ ...props }) {
  const [activeTab, setActiveTab] = useState(NotificationTabs.All);
  const [notifications, setNotifications] = useState({ all: [], unread: [] });

  useEffect(() => {
    const unsubscribe = notificationService.subscribeToNotifications((allNotifications) => {
      setNotifications({
        all: allNotifications,
        unread: allNotifications.filter((n) => !n.read),
      });
    });

    return () => unsubscribe();
  }, []);

  const markAllAsRead = async () => {
    await notificationService.markAllAsRead();
  };

  const activeNotifications = notifications[activeTab];

  return (
    <Card className="w-full max-w-[460px]" {...props}>
      <CardHeader className="flex flex-col px-0 pb-0">
        <div className="flex w-full items-center justify-between px-5 py-2">
          <div className="inline-flex items-center gap-1">
            <h4 className="inline-block align-middle text-large font-medium">Aktivitäten</h4>
          </div>
          <Button
            className="h-8 px-3"
            color="primary"
            radius="full"
            variant="light"
            onClick={markAllAsRead}
          >
            alle als gelesen markieren
          </Button>
        </div>
        <Tabs
          aria-label="Notifications"
          classNames={{
            base: "w-full",
            tabList: "gap-6 px-6 py-0 w-full relative rounded-none border-b border-divider",
            cursor: "w-full",
            tab: "max-w-fit px-2 h-12",
          }}
          color="primary"
          selectedKey={activeTab}
          variant="underlined"
          onSelectionChange={(selected) => setActiveTab(selected)}
        >
          <Tab
            key="all"
            title={<div className="flex items-center space-x-2"><span>Alle</span></div>}
          />
          <Tab
            key="unread"
            title={
              <div className="flex items-center space-x-2">
                <span>Ungelesen</span>
                <Chip size="sm" variant="flat">{notifications.unread.length}</Chip>
              </div>
            }
          />
        </Tabs>
      </CardHeader>
      <CardBody className="w-full gap-0 p-0">
        <ScrollShadow className="h-[500px] w-full">
          {activeNotifications.length > 0 ? (
            activeNotifications.map((notification) => (
              <NotificationItem key={notification.id} {...notification} />
            ))
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center gap-2">
              <Icon className="text-default-400" icon="solar:bell-off-linear" width={40} />
              <p className="text-small text-default-400">Du hast keine Aktivitäten.</p>
            </div>
          )}
        </ScrollShadow>
      </CardBody>
    </Card>
  );
}