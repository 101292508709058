"use client";

import React from "react";
import { Avatar, Badge } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import notificationService from "@/services/notificationService";

const NotificationItem = React.forwardRef(
  ({ id, title, message, type, url, read, createdAt, className, ...props }, ref) => {
    const markAsRead = async () => {
      await notificationService.markAsRead(id);
    };

    const handleLinkClick = async (e) => {
      if (url) {
        await markAsRead();
      }
    };

    return (
      <div
        ref={ref}
        className={cn(
          "flex gap-3 border-b border-divider px-6 py-4",
          { "bg-primary-50/50": !read },
          className
        )}
        {...props}
        onClick={markAsRead}
      >
        <div className="relative flex-none">
          <Badge
            color="primary"
            content=""
            isInvisible={read}
            placement="bottom-right"
            shape="circle"
          >
            <Avatar
              icon={<Icon icon="solar:bell-outline" />}
              classNames={{
                icon: "text-black/80",
              }}
            />
          </Badge>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-small text-foreground">
            <strong className="font-medium">{title}</strong> {message}
          </p>
          <time className="text-tiny text-default-400">
            {new Date(createdAt.toDate()).toLocaleString()}
          </time>
          {url && (
            <a href={url} target="_blank" className="text-primary" onClick={handleLinkClick}>
              Aufrufen
            </a>
          )}
        </div>
      </div>
    );
  }
);

export default NotificationItem;