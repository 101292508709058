"use client";

import React from "react";
import { Icon } from "@iconify/react";
import { cn } from "@nextui-org/react";
import { countries, states } from "./types";
import { useAppPushCreator } from "./AppPushCreator";
import JsonView from "react18-json-view";

const AppPushCreatorOverview = React.forwardRef(({ className, ...props }, ref) => {
  const { state, updateField } = useAppPushCreator();

  const appearanceNoneClassName =
    "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none";

  const inputProps = {
    labelPlacement: "outside",
    classNames: {
      label:
        "text-small font-medium text-default-700 group-data-[filled-within=true]:text-default-700",
    },
  };

  const numberInputProps = {
    labelPlacement: "outside",
    classNames: {
      label:
        "text-small font-medium text-default-700 group-data-[filled-within=true]:text-default-700",
      input: appearanceNoneClassName,
    },
  };

  const selectProps = {
    labelPlacement: "outside",
    classNames: {
      label: "text-small font-medium text-default-700 group-data-[filled=true]:text-default-700",
    },
  };

  const NumberInput = ({ className, ...props }) => (
    <input
      className={cn(
        "rounded-sm bg-transparent text-sm text-default-foreground",
        className,
        appearanceNoneClassName,
      )}
      min={0}
      minLength={0}
      type="number"
      {...props}
    />
  );

  return (
    <>
      <div className="text-3xl font-bold leading-9 text-default-foreground">Übersicht</div>
      <div className="py-4 text-base leading-5 text-default-500">Deine Push-Notification:</div>
      <JsonView src={state.json} />
    </>
  );
});

AppPushCreatorOverview.displayName = "AppPushCreatorOverview";

export default AppPushCreatorOverview;
