import { Button, Card, CardBody, CardHeader, Input, Radio, RadioGroup, Switch } from '@nextui-org/react';
import { useState, useRef } from 'react';

const FoodFormComponent = ({ onSubmit }) => {
    const [inputs, setInputs] = useState({
        name : "",
        comment: "",
        dateOfOffer: "today",
        estDeliveryTime: new Date((new Date()).getTime() + 120*60000).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}),
        offerEnd: new Date((new Date()).getTime() + 60*60000).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}),
        menuFile: "",
        fileName: "",
        location: "wesel",
        menuUrl: "",
        paymentPreferences: {
            cashOnly: true, 
            cashAccepted: true, 
            payPal: false, 
            payPalMail: ""},
    });

    const [errors, setErrors] = useState({
        name: false,
        paypal: false
    });

    const submitForm = (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (inputs.name === "") {
            setErrors((prevErrors) => ({...prevErrors, name: true}));
            return;
        } else if (inputs.paymentPreferences.payPal && inputs.paymentPreferences.payPalMail === '') {
            setErrors((prevErrors) => ({...prevErrors, paypal: true}));
            return;
        }
        onSubmit(inputs);
    }

    const [isLoading, setIsLoading] = useState(false);

    const menuInput = useRef(null);

    const handleInput = (event) => {
        var {name, value, type, checked, files} = event.target;
        if (name === "name") {
            setErrors((prevErrors) => ({...prevErrors, name: false}));
        } else if (name === "paymentPreferences.payPalMail") {
            setErrors((prevErrors) => ({...prevErrors, paypal: false}));
        }

        if (name.includes("paymentPreferences")) {
            name = name.split('.')[1];
            if (type === "checkbox") {
                value = checked;
            }
            setInputs((prevInputs) => ({...prevInputs, paymentPreferences: { ...prevInputs.paymentPreferences, [name]: value}}));
        } else {
            if (type === "file" && files[0]) {
                value = files[0];
                var filenamename = files[0].name;
                setInputs((prevInputs) => ({...prevInputs, menuFile: value, fileName: filenamename}));
            }
            setInputs((prevInputs) => ({...prevInputs, [name]: value}));
        }
    }

    return (
        <form className={'w-full p-4 pt-0' + (isLoading ? " opacity-50" : "")} onSubmit={submitForm}>
            <Card className="p-2">
                <CardHeader>
                    <div className="flex items-center ">
                        <div className="p-1 h-auto rounded-full mr-2 icon-bg-circle">
                            <i className="fa-regular fa-burger-fries"></i>
                        </div>
                        Wo wird bestellt?
                    </div>
                </CardHeader>
                <hr className="mt-0 mx-3" style={{borderColor: "rgb(229 231 235 / 54%)"}}></hr>
                <CardBody>
                    <Input errorMessage={errors.name && "Bitte Namen eintragen"} isInvalid={errors.name} name="name" value={inputs.name} onChange={handleInput} placeholder="Woher kommt das Essen?" startContent={
                        <i className="fa-regular fa-fork-knife"></i>
                    }></Input>
                    <Input name="comment" value={inputs.comment} onChange={handleInput} className="mt-3" placeholder="Anmerkungen / Details" startContent={
                        <i className="fa-regular fa-file"></i>
                    }></Input>
                    <RadioGroup name="location" onChange={handleInput} className="mt-3" label="Standort" orientation='horizontal' defaultValue={inputs.location}>
                        <Radio value="wesel">Wesel</Radio>
                        <Radio value="duisburg">Duisburg</Radio>
                    </RadioGroup>
                </CardBody>
            </Card>
            <Card className="p-2 w-full mt-3">
                <CardHeader>
                <div className="flex items-center">
                    <div className="p-1 h-auto rounded-full mr-2 icon-bg-circle">
                        <i className="fa-regular fa-plate-utensils"></i>
                    </div>
                    Speisekarte
                </div>
                </CardHeader>
                <hr className="mt-0 mx-3" style={{borderColor: "#e5e7eb9e"}}></hr>
                <CardBody>
                    <Input name="menuFile" onChange={handleInput} type="file" className="hidden" ref={menuInput}></Input>
                    <Button className="red-white-badge w-full" style={{borderRadius: "10px"}} onClick={() => menuInput.current.click()}>
                        <i className="fa-light fa-image"></i>{ !inputs.menuFile && ( <span>Speisekarte als Datei hochladen</span> )}{inputs.menuFile && (<span>{inputs.fileName} hochgeladen</span>)}
                    </Button>
                    <Input name="menuUrl" value={inputs.menuUrl} onChange={handleInput} className="mt-3" placeholder="Webseite / Online-Speisekarte" startContent={
                        <i className="fa-regular fa-globe"></i>
                    }></Input>
                </CardBody>
            </Card>
            <Card className="p-2 mt-3">
                <CardHeader>
                    <div className="flex items-center">
                        <div className="p-1 h-auto rounded-full mr-2 icon-bg-circle">
                            <i className="fa-regular fa-clock"></i>
                        </div>
                        Zeitangaben
                    </div>
                </CardHeader>
                <hr className="mt-0 mx-3" style={{borderColor: "#e5e7eb9e"}}></hr>
                <CardBody>
                    <RadioGroup name="dateOfOffer" onChange={handleInput} label="Datum" orientation='horizontal' defaultValue='today'>
                        <Radio value="today">Heute</Radio>
                        <Radio value="tomorrow">Morgen</Radio>
                    </RadioGroup>
                    <Input name="offerEnd" value={inputs.offerEnd} className="mt-3" onChange={handleInput} type="time" label="Bestellschluss" startContent={
                        <i className="fa-regular fa-hourglass"></i>
                    }></Input>
                    <Input name="estDeliveryTime" value={inputs.estDeliveryTime} onChange={handleInput} className="mt-3" type="time" label="~ Lieferzeit" startContent={
                        <i className="fa-regular fa-moped"></i>
                    }></Input>
                </CardBody>
            </Card>
            <Card className="p-2 mt-3">
                <CardHeader>
                    <div className="flex items-center ">
                        <div className="p-1 h-auto rounded-full mr-2 icon-bg-circle">
                            <i className="fa-regular fa-cash-register"></i>
                        </div>
                        Zahlung
                    </div>
                </CardHeader>
                <hr className="mt-0 mx-3" style={{borderColor: "#e5e7eb9e"}}></hr>
                <CardBody>
                    <Switch name="paymentPreferences.cashOnly" onChange={handleInput} defaultSelected={inputs.paymentPreferences.cashOnly}>Nur Vorkasse (Bar)</Switch>
                    { inputs.paymentPreferences.cashOnly === false && (
                        <>
                            <Switch name="paymentPreferences.cashAccepted" onChange={handleInput} className="mt-3" defaultSelected>Barzahlung</Switch>
                            <Switch name="paymentPreferences.payPal" className="mt-3" onChange={handleInput}>PayPal Freunde</Switch>
                            { inputs.paymentPreferences['payPal'] && (
                                <>
                                    <Input isInvalid={errors.paypal} errorMessage={errors.paypal && "Bitte füge eine E-Mail-Adresse hinzu"} name="paymentPreferences.payPalMail" onChange={handleInput} className="mt-3" placeholder="PayPal-Email-Adresse" startContent={
                                        <i className="fa-regular fa-envelope"></i>
                                    }></Input>
                                </>
                            )}
                        </>
                        )
                    }
                </CardBody>
            </Card>
            <Button isLoading={isLoading} type="submit" title="Mittagstisch abschicken" iconOnly className="mt-3 rounded-full float-right red-white-badge">{ !isLoading && (<i className="fa-regular fa-paper-plane"></i>)}</Button>
        </form>
    )
}

export default FoodFormComponent;