import { Button, Card, Input, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const types = [
    { key: "orderId", label: "Auftragsnummer" },
    { key: "email", label: "E-Mail Adresse" },
    { key: "phone", label: "Telefonnummer" },
    { key: "customerId", label: "Kundennummer" },
    { key: "sn", label: "Seriennummer" },
];

function WaWiSearchComponent({ presetId, presetType }) {
    const navigate = useNavigate();
    const [platform, setPlatform] = React.useState("sellOrders");
    const [type, setType] = React.useState("orderId");
    const [value, setValue] = React.useState("");

    useEffect(() => {
        if (presetType) {
            setType(presetType);
        }
        if (presetId) {
            setValue(presetId);
        }
    }, [presetType, presetId]);

    const handleSearch = () => {
        let finalType = type;

        const route = platform === "sellOrders" ? "/admin-wawi/sellOrder" : "/admin-wawi/buyOrder";
        navigate(`${route}?type=${finalType}&id=${value}`);
    };

    return (
        <>
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Warenwirtschaft</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 pb-6">
                Finde Ankaufaufträge und Bestellungen und sehe diese so, wie sie dem Kunden im Kundencenter angezeigt.
            </h2>
            <Card className="w-full rounded-lg border bg-background shadow mb-6" css={{ maxWidth: '900px', textAlign: 'center' }}>
                <div className="flex flex-col gap-3 p-4">
                    <div className="flex flex-col md:flex-row md:space-x-2 w-full items-center">
                        <div className="w-full md:w-1/6">
                            <Select
                                fullWidth
                                disallowEmptySelection
                                label="Plattform"
                                variant="bordered"
                                size="sm"
                                placeholder="Wähle eine Plattform"
                                selectedKeys={[platform]}
                                onChange={(e) => setPlatform(e.target.value)}
                            >
                                <SelectItem key="sellOrders">Ankauf</SelectItem>
                                <SelectItem key="buyOrders">Verkauf</SelectItem>
                            </Select>
                        </div>
                        <div className="w-full md:w-1/6">
                            <Select
                                fullWidth
                                disallowEmptySelection
                                label="Typ"
                                variant="bordered"
                                size="sm"
                                placeholder="Wähle einen Suchtyp"
                                selectedKeys={[type]}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {types.map((type) => (
                                    <SelectItem key={type.key}>{type.label}</SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full md:w-3/6">
                            <Input
                                fullWidth
                                label="Referenz"
                                placeholder="Gib eine Referenz ein"
                                value={value}
                                variant="bordered"
                                size="sm"
                                onValueChange={setValue}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                            />
                        </div>
                        <div className="w-full md:w-1/6 flex justify-center">
                            <Button color="primary" fullWidth onPress={handleSearch} size="lg" auto>
                                Suchen
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default WaWiSearchComponent;
