import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WikiHome from './WikiHome';
import WikiCategory from './WikiCategory';
import WikiEditor from './WikiEditor';

const WikiPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const [activeComponent, setActiveComponent] = useState('WikiHome');

  useEffect(() => {
    const path = location.pathname;
    const segments = path.split('/').filter(Boolean);
    const idFromPath = segments.length > 1 ? segments[1] : null;
  
    if (path.startsWith('/wiki/create')) {
      setActiveComponent('WikiEditorCreate');
    } else if (path.startsWith('/wiki/edit') && idFromPath) {
      setActiveComponent('WikiEditorEdit');
    } else if (idFromPath) {
      setActiveComponent('WikiCategory');
    } else {
      setActiveComponent('WikiHome');
    }
  }, [location.pathname]);  

  return (
    <>
      {activeComponent === 'WikiHome' && <WikiHome />}
      {activeComponent === 'WikiEditorCreate' && <WikiEditor />}
      {activeComponent === 'WikiEditorEdit' && <WikiEditor id={id} />}
      {activeComponent === 'WikiCategory' && <WikiCategory id={id} />}
    </>
  );
};

export default WikiPage;
